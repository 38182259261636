"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.companies = [
    {
        color: "transparent",
        details: {
            imageUrl: "",
            jobDescription: "",
            jobTitle: "",
            technologies: []
        },
        end: "",
        start: "",
        length: 0,
        name: ""
    },
    {
        start: "05_2016",
        end: "04_2017",
        color: "#2E2D2C",
        name: "PEAK-14",
        length: 12,
        details: {
            jobTitle: "Full Stack Developer",
            jobDescription: "Assistance in creating admin tools for a content asset management system called 'Cavok', The system was created using web technologies.",
            imageUrl: "/Assets/Images/1519875315058.jfif",
            technologies: ["HTML", "CSS", "JavaScript", "JQuery", "Zend Framework 2", "SOAP"]
        }
    },
    {
        start: "06_2017",
        end: "09_2018",
        color: "#224F76",
        name: "Incloud",
        length: 16,
        details: {
            jobTitle: "Full Stack Developer",
            jobDescription: "Work on different projects for different clients. Main focus on web projects ranging from a simple website to complex systems involving servers and databases.",
            imageUrl: "/Assets/Images/1610110864015.jfif",
            technologies: ["HTML", "CSS", "JavaScript", "Angular 2", "Ionic", "Symfony", "Node.js", "MariaDB"]
        }
    },
    {
        start: "09_2018",
        end: "12_2018",
        color: "green",
        name: "Hessischer Ideenwettbewerb",
        length: 3,
        details: {
            jobTitle: "Full Stack Developer",
            jobDescription: "Creation of a website called 'SyncIt' that allows users to watch videos together with shared and synchronized controls. (More info on the project can be found under 'My Work')",
            imageUrl: "",
            technologies: ["HTML", "CSS", "JavaScript", "React", "Node.js", "MongoDB"]
        }
    },
    {
        start: "01_2019",
        end: "01_2020",
        color: "#E4002B",
        name: "Telespazio-vega",
        length: 12,
        details: {
            jobTitle: "Full Stack Developer",
            jobDescription: "Assistance in the creation of 'Enable', a mission planning and satellite operations tool for the space industry.",
            imageUrl: "/Assets/Images/1600064168545.jfif",
            technologies: ["HTML", "CSS", "JavaScript", "Angular 2+", "Java Spring Boot", "Elasticsearch"]
        }
    },
    {
        start: "04_2020",
        end: "04_2022",
        color: "#9935e4",
        name: "Solenix Deutschland",
        length: 24,
        details: {
            jobTitle: "Web Developer",
            jobDescription: "Work on different smaller projects for customers, mainly in the web domain. Mainly work on 'MUST' a monitoring system for satellite operations.",
            imageUrl: "/Assets/Images/1519898416543.jfif",
            technologies: ["HTML", "CSS", "JavaScript", "Angular 2+", "Electron"]
        }
    }
];
exports.timeLineData = {
    2016: [0, 0, 0, 0, 1, 1, 1, 1, 1, 1, 1, 1],
    2017: [1, 1, 1, 1, 0, 2, 2, 2, 2, 2, 2, 2],
    2018: [2, 2, 2, 2, 2, 2, 2, 2, 2, 3, 3, 3],
    2019: [4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4],
    2020: [0, 0, 0, 5, 5, 5, 5, 5, 5, 5, 5, 5],
    2021: [5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5, 5],
    2022: [5, 5, 5, 5, 0, 0, 0, 0, 0, 0, 0, 0],
};
/**
 * PEAK
 * - development of an content asset management system called Cavok
 * - Fullstack development
 * - Zenn Framework 2
 * - Jquery
 *
 * INCLOUD
 * - multiple different web projects for different clients
 * - Angular2
 * - Symfony PHP
 * - Ionic
 * - Node JS
 *
 * TELESPAZIO
 * - Development of ENABLE a mission planning tool for space operations
 * - Angular
 * - Elastic Search
 * - Java Spring Boot
 *
 * SOLENIX
 * - development of different technologies, mostly MUST a tool for space operations
 * - Angular
 * - Electron
 */
