"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const react_svg_1 = require("react-svg");
require("./Footer.style.scss");
const react_router_dom_1 = require("react-router-dom");
class FooterComponent extends React.Component {
    constructor(props) {
        super(props);
    }
    scrollTop() {
        document.getElementById("scrollCheat").scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
    }
    render() {
        return (React.createElement("div", { className: "footer-component" },
            React.createElement("div", { className: "circle-wrapper" },
                React.createElement("div", { className: "half-circle", onClick: this.scrollTop.bind(this) },
                    React.createElement(react_svg_1.ReactSVG, { className: "global-svg icon shadow", src: "/Assets/Icons/up.svg", style: { width: "40", height: "40", fill: "#000" } }))),
            React.createElement("div", { className: "footer" },
                React.createElement("div", { className: "copyright" },
                    "\u00A9 ",
                    new Date().getFullYear(),
                    " Markus Lindner"),
                React.createElement("div", { className: "links" },
                    React.createElement(react_router_dom_1.Link, { className: "menu-item", to: "/about" }, "About"),
                    React.createElement(react_router_dom_1.Link, { className: "menu-item", to: "/skills" }, "Skills"),
                    React.createElement(react_router_dom_1.Link, { className: "menu-item", to: "/mywork" }, "My Work"),
                    React.createElement(react_router_dom_1.Link, { className: "menu-item", to: "/contact" }, "Contact")),
                React.createElement("div", { className: "data-links" },
                    React.createElement(react_router_dom_1.Link, { to: "/impressum" }, "Impressum"),
                    React.createElement(react_router_dom_1.Link, { to: "/datenschutz" }, "Datenschutz")))));
    }
}
exports.FooterComponent = FooterComponent;
