"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
class ScreenDimensionService {
    static updateScreenResolution() {
        let win = window, doc = document, docElem = doc.documentElement, body = doc.getElementsByTagName('body')[0], x = win.innerWidth || docElem.clientWidth || body.clientWidth, y = win.innerHeight || docElem.clientHeight || body.clientHeight;
        this.screenX = x;
        this.screenY = y;
        for (let i = 0; i < ScreenDimensionService.observers.length; i++) {
            ScreenDimensionService.observers[i](x, y);
        }
    }
    static listenForChange(cb) {
        this.observers.push(cb);
    }
}
exports.ScreenDimensionService = ScreenDimensionService;
ScreenDimensionService.screenX = 0;
ScreenDimensionService.screenY = 0;
ScreenDimensionService.observers = [];
