"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SKILLS_LIST = {
    itRelated: [
        {
            name: "HTML",
            url: "https://wikipedia.org/wiki/HTML"
        },
        {
            name: "CSS",
            url: "https://wikipedia.org/wiki/Cascading_Style_Sheets"
        },
        {
            name: "SASS",
            url: "https://wikipedia.org/wiki/Sass_(Stylesheet-Sprache)"
        },
        {
            name: "Javascript",
            url: "https://wikipedia.org/wiki/JavaScript"
        },
        {
            name: "Typescript",
            url: "https://wikipedia.org/wiki/TypeScript"
        },
        {
            name: "NodeJS",
            url: "https://wikipedia.org/wiki/Node.js"
        },
        {
            name: "NPM",
            url: "https://nodejs.org/en/knowledge/getting-started/npm/what-is-npm/"
        },
        {
            name: "NoSQL",
            url: "https://wikipedia.org/wiki/NoSQL"
        },
        {
            name: "Angular",
            url: "https://en.wikipedia.org/wiki/Angular_(web_framework)"
        },
        {
            name: "React",
            url: "https://en.wikipedia.org/wiki/React_(web_framework)"
        },
        {
            name: "Jquery",
            url: "https://wikipedia.org/wiki/JQuery"
        },
        {
            name: "Pixi-JS",
            url: "https://www.pixijs.com/"
        },
        {
            name: "Progressive Web Apps",
            url: "https://en.wikipedia.org/wiki/Progressive_web_application"
        },
        {
            name: "JAVA",
            url: "https://en.wikipedia.org/wiki/Java_(programming_language)"
        },
        {
            name: "Spring-Boot",
            url: "https://de.wikipedia.org/wiki/Spring_(Framework)"
        },
        {
            name: "Kibana",
            url: "https://en.wikipedia.org/wiki/Kibana"
        },
        {
            name: "Elastic Search",
            url: "https://en.wikipedia.org/wiki/Elasticsearch"
        },
        {
            name: "JSON",
            url: "https://wikipedia.org/wiki/JavaScript_Object_Notation"
        },
        {
            name: "YML",
            url: "https://wikipedia.org/wiki/YAML"
        },
        {
            name: "XML",
            url: "https://wikipedia.org/wiki/Extensible_Markup_Language"
        },
        {
            name: "Git",
            url: "https://wikipedia.org/wiki/Git"
        },
        {
            name: "Gulp",
            url: "https://wikipedia.org/wiki/Gulp.js"
        },
        {
            name: "Webpack",
            url: "https://wikipedia.org/wiki/Webpack"
        },
        {
            name: "Compute Shader",
            url: "https://de.wikipedia.org/wiki/Compute-Shader"
        },
        {
            name: "HLSL",
            url: "https://de.wikipedia.org/wiki/High_Level_Shading_Language"
        },
        {
            name: "Procedural Generation",
            url: "https://en.wikipedia.org/wiki/Procedural_generation"
        },
        {
            name: "C Sharp",
            url: "https://en.wikipedia.org/wiki/C_Sharp_(programming_language)"
        }
    ],
    programs: [
        {
            name: "FL-Studio",
            url: "https://wikipedia.org/wiki/FL_Studio"
        },
        {
            name: "Blender",
            url: "https://www.blender.org/"
        },
        {
            name: "Pyxel Edit",
            url: "https://pyxeledit.com/"
        },
        {
            name: "Audacity",
            url: "https://en.wikipedia.org/wiki/Audacity_(audio_editor)"
        },
        {
            name: "Photoshop",
            url: "https://www.adobe.com/de/products/photoshop.html"
        },
        {
            name: "Gimp",
            url: "https://wikipedia.org/wiki/GIMP"
        },
        {
            name: "DragonBones",
            url: "http://dragonbones.com/en/index.html#.Xjv90WhKiUk"
        },
        {
            name: "Unity - Game Engine",
            url: "https://unity.com/"
        }
    ]
};
