"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const Seeder_1 = require("./Seeder");
class PineTree {
    constructor(levels, trunk, x, y, hasRandomness) {
        this.lengthY = 0;
        this.seed = Math.random() * 10000 | 0;
        this.seeder = new Seeder_1.Seeder(this.seed);
        this.levels = levels;
        this.trunk = trunk;
        this.x = x;
        this.y = y;
        this.hasRandomness = hasRandomness;
    }
    draw(ctx, offset, color) {
        this.drawTriangle(ctx, this.x, this.y - this.trunk + offset, 1, 1, color);
    }
    drawTrunk(ctx, topX, topY, bottomX, bottomY, color) {
        ctx.save();
        ctx.beginPath();
        ctx.moveTo(topX, topY);
        ctx.lineTo(bottomX, bottomY);
        ctx.closePath();
        ctx.strokeStyle = color;
        ctx.stroke();
        ctx.restore();
        this.seeder.reseed(this.seed);
    }
    drawTriangle(ctx, x, y, scale, iteration, color) {
        let yPos = y - this.trunk * scale;
        let random;
        if (this.hasRandomness) {
            let min = (this.trunk * scale / 2) * -1;
            let max = this.trunk * scale / 2;
            random = this.seeder.randomFloat(min, max);
        }
        else {
            random = 0;
        }
        ctx.save();
        ctx.beginPath();
        ctx.moveTo(x, yPos + random);
        ctx.lineTo(x - this.trunk * scale, y + random);
        ctx.lineTo(x + this.trunk * scale, y + random);
        ctx.closePath();
        ctx.fillStyle = color;
        ctx.fill();
        ctx.restore();
        this.lengthY += yPos + random;
        if (iteration >= this.levels) {
            this.drawTrunk(ctx, x, yPos + random, x, this.lengthY, color);
        }
        else {
            this.drawTriangle(ctx, x, yPos + random, scale * 0.8, iteration + 1, color);
        }
    }
}
exports.PineTree = PineTree;
