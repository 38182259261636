"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
require("./Datenschutz.style.scss");
class DatenschutzView extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (React.createElement("div", { className: 'datenschutz-view' },
            React.createElement("div", { className: "datenschutz-content" },
                React.createElement("h2", null, "Datenschutzerkl\u00E4rung"),
                React.createElement("h3", { id: "dsg-general-intro" }),
                React.createElement("p", null,
                    "Diese Datenschutzerkl\u00E4rung kl\u00E4rt Sie \u00FCber die Art, den Umfang und Zweck der Verarbeitung von personenbezogenen Daten (nachfolgend kurz \u201EDaten\u201C) im Rahmen der Erbringung unserer Leistungen sowie innerhalb unseres Onlineangebotes und der mit ihm verbundenen Webseiten, Funktionen und Inhalte sowie externen Onlinepr\u00E4senzen, wie z.B. unser Social Media Profile auf (nachfolgend gemeinsam bezeichnet als \u201EOnlineangebot\u201C). Im Hinblick auf die verwendeten Begrifflichkeiten, wie z.B. \u201EVerarbeitung\u201C oder \u201EVerantwortlicher\u201C verweisen wir auf die Definitionen im Art. 4 der Datenschutzgrundverordnung (DSGVO). ",
                    React.createElement("br", null),
                    React.createElement("br", null)),
                React.createElement("h3", { id: "dsg-general-controller" }, "Verantwortlicher"),
                React.createElement("p", null,
                    React.createElement("span", { className: "tsmcontroller" },
                        "Markus, Lindner",
                        React.createElement("br", null),
                        "Pallaswiesen Stra\u00DFe 8",
                        React.createElement("br", null),
                        "64287 Darmstadt",
                        React.createElement("br", null),
                        "Deutschland",
                        React.createElement("br", null),
                        React.createElement("br", null),
                        "lindus.develops@gmail.com",
                        React.createElement("br", null),
                        "http://lindus.io/impressum")),
                React.createElement("h3", { id: "dsg-general-datatype" }, "Arten der verarbeiteten Daten"),
                React.createElement("p", null,
                    "- Bestandsdaten (z.B., Personen-Stammdaten, Namen oder Adressen).",
                    React.createElement("br", null),
                    "- Kontaktdaten (z.B., E-Mail, Telefonnummern).",
                    React.createElement("br", null),
                    "- Inhaltsdaten (z.B., Texteingaben, Fotografien, Videos).",
                    React.createElement("br", null),
                    "- Nutzungsdaten (z.B., besuchte Webseiten, Interesse an Inhalten, Zugriffszeiten).",
                    React.createElement("br", null),
                    "- Meta-/Kommunikationsdaten (z.B., Ger\u00E4te-Informationen, IP-Adressen)."),
                React.createElement("h3", { id: "dsg-general-datasubjects" }, "Kategorien betroffener Personen"),
                React.createElement("p", null,
                    "Besucher und Nutzer des Onlineangebotes (Nachfolgend bezeichnen wir die betroffenen Personen zusammenfassend auch als \u201ENutzer\u201C).",
                    React.createElement("br", null)),
                React.createElement("h3", { id: "dsg-general-purpose" }, "Zweck der Verarbeitung"),
                React.createElement("p", null,
                    "- Zurverf\u00FCgungstellung des Onlineangebotes, seiner Funktionen und Inhalte.",
                    React.createElement("br", null),
                    "- Beantwortung von Kontaktanfragen und Kommunikation mit Nutzern.",
                    React.createElement("br", null),
                    "- Sicherheitsma\u00DFnahmen.",
                    React.createElement("br", null),
                    "- Reichweitenmessung/Marketing",
                    React.createElement("br", null),
                    React.createElement("span", { className: "tsmcom" })),
                React.createElement("h3", { id: "dsg-general-terms" }, "Verwendete Begrifflichkeiten "),
                React.createElement("p", null,
                    "\u201EPersonenbezogene Daten\u201C sind alle Informationen, die sich auf eine identifizierte oder identifizierbare nat\u00FCrliche Person (im Folgenden \u201Ebetroffene Person\u201C) beziehen; als identifizierbar wird eine nat\u00FCrliche Person angesehen, die direkt oder indirekt, insbesondere mittels Zuordnung zu einer Kennung wie einem Namen, zu einer Kennnummer, zu Standortdaten, zu einer Online-Kennung (z.B. Cookie) oder zu einem oder mehreren besonderen Merkmalen identifiziert werden kann, die Ausdruck der physischen, physiologischen, genetischen, psychischen, wirtschaftlichen, kulturellen oder sozialen Identit\u00E4t dieser nat\u00FCrlichen Person sind.",
                    React.createElement("br", null),
                    React.createElement("br", null),
                    "\u201EVerarbeitung\u201C ist jeder mit oder ohne Hilfe automatisierter Verfahren ausgef\u00FChrte Vorgang oder jede solche Vorgangsreihe im Zusammenhang mit personenbezogenen Daten. Der Begriff reicht weit und umfasst praktisch jeden Umgang mit Daten.",
                    React.createElement("br", null),
                    React.createElement("br", null),
                    "\u201EPseudonymisierung\u201C die Verarbeitung personenbezogener Daten in einer Weise, dass die personenbezogenen Daten ohne Hinzuziehung zus\u00E4tzlicher Informationen nicht mehr einer spezifischen betroffenen Person zugeordnet werden k\u00F6nnen, sofern diese zus\u00E4tzlichen Informationen gesondert aufbewahrt werden und technischen und organisatorischen Ma\u00DFnahmen unterliegen, die gew\u00E4hrleisten, dass die personenbezogenen Daten nicht einer identifizierten oder identifizierbaren nat\u00FCrlichen Person zugewiesen werden.",
                    React.createElement("br", null),
                    React.createElement("br", null),
                    "\u201EProfiling\u201C jede Art der automatisierten Verarbeitung personenbezogener Daten, die darin besteht, dass diese personenbezogenen Daten verwendet werden, um bestimmte pers\u00F6nliche Aspekte, die sich auf eine nat\u00FCrliche Person beziehen, zu bewerten, insbesondere um Aspekte bez\u00FCglich Arbeitsleistung, wirtschaftliche Lage, Gesundheit, pers\u00F6nliche Vorlieben, Interessen, Zuverl\u00E4ssigkeit, Verhalten, Aufenthaltsort oder Ortswechsel dieser nat\u00FCrlichen Person zu analysieren oder vorherzusagen.",
                    React.createElement("br", null),
                    React.createElement("br", null),
                    "Als \u201EVerantwortlicher\u201C wird die nat\u00FCrliche oder juristische Person, Beh\u00F6rde, Einrichtung oder andere Stelle, die allein oder gemeinsam mit anderen \u00FCber die Zwecke und Mittel der Verarbeitung von personenbezogenen Daten entscheidet, bezeichnet.",
                    React.createElement("br", null),
                    React.createElement("br", null),
                    "\u201EAuftragsverarbeiter\u201C eine nat\u00FCrliche oder juristische Person, Beh\u00F6rde, Einrichtung oder andere Stelle, die personenbezogene Daten im Auftrag des Verantwortlichen verarbeitet.",
                    React.createElement("br", null)),
                React.createElement("h3", { id: "dsg-general-legalbasis" }, "Ma\u00DFgebliche Rechtsgrundlagen"),
                React.createElement("p", null,
                    "Nach Ma\u00DFgabe des Art. 13 DSGVO teilen wir Ihnen die Rechtsgrundlagen unserer Datenverarbeitungen mit. F\u00FCr Nutzer aus dem Geltungsbereich der Datenschutzgrundverordnung (DSGVO), d.h. der EU und des EWG gilt, sofern die Rechtsgrundlage in der Datenschutzerkl\u00E4rung nicht genannt wird, Folgendes: ",
                    React.createElement("br", null),
                    "Die Rechtsgrundlage f\u00FCr die Einholung von Einwilligungen ist Art. 6 Abs. 1 lit. a und Art. 7 DSGVO;",
                    React.createElement("br", null),
                    "Die Rechtsgrundlage f\u00FCr die Verarbeitung zur Erf\u00FCllung unserer Leistungen und Durchf\u00FChrung vertraglicher Ma\u00DFnahmen sowie Beantwortung von Anfragen ist Art. 6 Abs. 1 lit. b DSGVO;",
                    React.createElement("br", null),
                    "Die Rechtsgrundlage f\u00FCr die Verarbeitung zur Erf\u00FCllung unserer rechtlichen Verpflichtungen ist Art. 6 Abs. 1 lit. c DSGVO;",
                    React.createElement("br", null),
                    "F\u00FCr den Fall, dass lebenswichtige Interessen der betroffenen Person oder einer anderen nat\u00FCrlichen Person eine Verarbeitung personenbezogener Daten erforderlich machen, dient Art. 6 Abs. 1 lit. d DSGVO als Rechtsgrundlage.",
                    React.createElement("br", null),
                    "Die Rechtsgrundlage f\u00FCr die erforderliche Verarbeitung zur Wahrnehmung einer Aufgabe, die im \u00F6ffentlichen Interesse liegt oder in Aus\u00FCbung \u00F6ffentlicher Gewalt erfolgt, die dem Verantwortlichen \u00FCbertragen wurde ist Art. 6 Abs. 1 lit. e DSGVO. ",
                    React.createElement("br", null),
                    "Die Rechtsgrundlage f\u00FCr die Verarbeitung zur Wahrung unserer berechtigten Interessen ist Art. 6 Abs. 1 lit. f DSGVO. ",
                    React.createElement("br", null),
                    "Die Verarbeitung von Daten zu anderen Zwecken als denen, zu denen sie ehoben wurden, bestimmt sich nach den Vorgaben des Art 6 Abs. 4 DSGVO. ",
                    React.createElement("br", null),
                    "Die Verarbeitung von besonderen Kategorien von Daten (entsprechend Art. 9 Abs. 1 DSGVO) bestimmt sich nach den Vorgaben des Art. 9 Abs. 2 DSGVO. ",
                    React.createElement("br", null)),
                React.createElement("h3", { id: "dsg-general-securitymeasures" }, "Sicherheitsma\u00DFnahmen"),
                React.createElement("p", null,
                    "Wir treffen nach Ma\u00DFgabe der gesetzlichen Vorgabenunter Ber\u00FCcksichtigung des Stands der Technik, der Implementierungskosten und der Art, des Umfangs, der Umst\u00E4nde und der Zwecke der Verarbeitung sowie der unterschiedlichen Eintrittswahrscheinlichkeit und Schwere des Risikos f\u00FCr die Rechte und Freiheiten nat\u00FCrlicher Personen, geeignete technische und organisatorische Ma\u00DFnahmen, um ein dem Risiko angemessenes Schutzniveau zu gew\u00E4hrleisten.",
                    React.createElement("br", null),
                    React.createElement("br", null),
                    "Zu den Ma\u00DFnahmen geh\u00F6ren insbesondere die Sicherung der Vertraulichkeit, Integrit\u00E4t und Verf\u00FCgbarkeit von Daten durch Kontrolle des physischen Zugangs zu den Daten, als auch des sie betreffenden Zugriffs, der Eingabe, Weitergabe, der Sicherung der Verf\u00FCgbarkeit und ihrer Trennung. Des Weiteren haben wir Verfahren eingerichtet, die eine Wahrnehmung von Betroffenenrechten, L\u00F6schung von Daten und Reaktion auf Gef\u00E4hrdung der Daten gew\u00E4hrleisten. Ferner ber\u00FCcksichtigen wir den Schutz personenbezogener Daten bereits bei der Entwicklung, bzw. Auswahl von Hardware, Software sowie Verfahren, entsprechend dem Prinzip des Datenschutzes durch Technikgestaltung und durch datenschutzfreundliche Voreinstellungen.",
                    React.createElement("br", null)),
                React.createElement("h3", { id: "dsg-general-coprocessing" }, "Zusammenarbeit mit Auftragsverarbeitern, gemeinsam Verantwortlichen und Dritten"),
                React.createElement("p", null,
                    "Sofern wir im Rahmen unserer Verarbeitung Daten gegen\u00FCber anderen Personen und Unternehmen (Auftragsverarbeitern, gemeinsam Verantwortlichen oder Dritten) offenbaren, sie an diese \u00FCbermitteln oder ihnen sonst Zugriff auf die Daten gew\u00E4hren, erfolgt dies nur auf Grundlage einer gesetzlichen Erlaubnis (z.B. wenn eine \u00DCbermittlung der Daten an Dritte, wie an Zahlungsdienstleister, zur Vertragserf\u00FCllung erforderlich ist), Nutzer eingewilligt haben, eine rechtliche Verpflichtung dies vorsieht oder auf Grundlage unserer berechtigten Interessen (z.B. beim Einsatz von Beauftragten, Webhostern, etc.). ",
                    React.createElement("br", null),
                    React.createElement("br", null),
                    "Sofern wir Daten anderen Unternehmen unserer Unternehmensgruppe offenbaren, \u00FCbermitteln oder ihnen sonst den Zugriff gew\u00E4hren, erfolgt dies insbesondere zu administrativen Zwecken als berechtigtes Interesse und dar\u00FCberhinausgehend auf einer den gesetzlichen Vorgaben entsprechenden Grundlage. ",
                    React.createElement("br", null)),
                React.createElement("h3", { id: "dsg-general-thirdparty" }, "\u00DCbermittlungen in Drittl\u00E4nder"),
                React.createElement("p", null, "Sofern wir Daten in einem Drittland (d.h. au\u00DFerhalb der Europ\u00E4ischen Union (EU), des Europ\u00E4ischen Wirtschaftsraums (EWR) oder der Schweizer Eidgenossenschaft) verarbeiten oder dies im Rahmen der Inanspruchnahme von Diensten Dritter oder Offenlegung, bzw. \u00DCbermittlung von Daten an andere Personen oder Unternehmen geschieht, erfolgt dies nur, wenn es zur Erf\u00FCllung unserer (vor)vertraglichen Pflichten, auf Grundlage Ihrer Einwilligung, aufgrund einer rechtlichen Verpflichtung oder auf Grundlage unserer berechtigten Interessen geschieht. Vorbehaltlich gesetzlicher oder vertraglicher Erlaubnisse, verarbeiten oder lassen wir die Daten in einem Drittland nur beim Vorliegen der gesetzlichen Voraussetzungen. D.h. die Verarbeitung erfolgt z.B. auf Grundlage besonderer Garantien, wie der offiziell anerkannten Feststellung eines der EU entsprechenden Datenschutzniveaus (z.B. f\u00FCr die USA durch das \u201EPrivacy Shield\u201C) oder Beachtung offiziell anerkannter spezieller vertraglicher Verpflichtungen."),
                React.createElement("h3", { id: "dsg-general-rightssubject" }, "Rechte der betroffenen Personen"),
                React.createElement("p", null,
                    "Sie haben das Recht, eine Best\u00E4tigung dar\u00FCber zu verlangen, ob betreffende Daten verarbeitet werden und auf Auskunft \u00FCber diese Daten sowie auf weitere Informationen und Kopie der Daten entsprechend den gesetzlichen Vorgaben.",
                    React.createElement("br", null),
                    React.createElement("br", null),
                    "Sie haben entsprechend. den gesetzlichen Vorgaben das Recht, die Vervollst\u00E4ndigung der Sie betreffenden Daten oder die Berichtigung der Sie betreffenden unrichtigen Daten zu verlangen.",
                    React.createElement("br", null),
                    React.createElement("br", null),
                    "Sie haben nach Ma\u00DFgabe der gesetzlichen Vorgaben das Recht zu verlangen, dass betreffende Daten unverz\u00FCglich gel\u00F6scht werden, bzw. alternativ nach Ma\u00DFgabe der gesetzlichen Vorgaben eine Einschr\u00E4nkung der Verarbeitung der Daten zu verlangen.",
                    React.createElement("br", null),
                    React.createElement("br", null),
                    "Sie haben das Recht zu verlangen, dass die Sie betreffenden Daten, die Sie uns bereitgestellt haben nach Ma\u00DFgabe der gesetzlichen Vorgaben zu erhalten und deren \u00DCbermittlung an andere Verantwortliche zu fordern. ",
                    React.createElement("br", null),
                    React.createElement("br", null),
                    "Sie haben ferner nach Ma\u00DFgabe der gesetzlichen Vorgaben das Recht, eine Beschwerde bei der zust\u00E4ndigen Aufsichtsbeh\u00F6rde einzureichen.",
                    React.createElement("br", null)),
                React.createElement("h3", { id: "dsg-general-revokeconsent" }, "Widerrufsrecht"),
                React.createElement("p", null, "Sie haben das Recht, erteilte Einwilligungen mit Wirkung f\u00FCr die Zukunft zu widerrufen."),
                React.createElement("h3", { id: "dsg-general-object" }, "Widerspruchsrecht"),
                React.createElement("p", null,
                    React.createElement("strong", null, "Sie k\u00F6nnen der k\u00FCnftigen Verarbeitung der Sie betreffenden Daten nach Ma\u00DFgabe der gesetzlichen Vorgaben jederzeit widersprechen. Der Widerspruch kann insbesondere gegen die Verarbeitung f\u00FCr Zwecke der Direktwerbung erfolgen.")),
                React.createElement("h3", { id: "dsg-general-cookies" }, "Cookies und Widerspruchsrecht bei Direktwerbung"),
                React.createElement("p", null,
                    "Als \u201ECookies\u201C werden kleine Dateien bezeichnet, die auf Rechnern der Nutzer gespeichert werden. Innerhalb der Cookies k\u00F6nnen unterschiedliche Angaben gespeichert werden. Ein Cookie dient prim\u00E4r dazu, die Angaben zu einem Nutzer (bzw. dem Ger\u00E4t auf dem das Cookie gespeichert ist) w\u00E4hrend oder auch nach seinem Besuch innerhalb eines Onlineangebotes zu speichern. Als tempor\u00E4re Cookies, bzw. \u201ESession-Cookies\u201C oder \u201Etransiente Cookies\u201C, werden Cookies bezeichnet, die gel\u00F6scht werden, nachdem ein Nutzer ein Onlineangebot verl\u00E4sst und seinen Browser schlie\u00DFt. In einem solchen Cookie kann z.B. der Inhalt eines Warenkorbs in einem Onlineshop oder ein Login-Status gespeichert werden. Als \u201Epermanent\u201C oder \u201Epersistent\u201C werden Cookies bezeichnet, die auch nach dem Schlie\u00DFen des Browsers gespeichert bleiben. So kann z.B. der Login-Status gespeichert werden, wenn die Nutzer diese nach mehreren Tagen aufsuchen. Ebenso k\u00F6nnen in einem solchen Cookie die Interessen der Nutzer gespeichert werden, die f\u00FCr Reichweitenmessung oder Marketingzwecke verwendet werden. Als \u201EThird-Party-Cookie\u201C werden Cookies bezeichnet, die von anderen Anbietern als dem Verantwortlichen, der das Onlineangebot betreibt, angeboten werden (andernfalls, wenn es nur dessen Cookies sind spricht man von \u201EFirst-Party Cookies\u201C).",
                    React.createElement("br", null),
                    React.createElement("br", null),
                    "Wir k\u00F6nnen tempor\u00E4re und permanente Cookies einsetzen und kl\u00E4ren hier\u00FCber im Rahmen unserer Datenschutzerkl\u00E4rung auf.",
                    React.createElement("br", null),
                    React.createElement("br", null),
                    "Falls die Nutzer nicht m\u00F6chten, dass Cookies auf ihrem Rechner gespeichert werden, werden sie gebeten die entsprechende Option in den Systemeinstellungen ihres Browsers zu deaktivieren. Gespeicherte Cookies k\u00F6nnen in den Systemeinstellungen des Browsers gel\u00F6scht werden. Der Ausschluss von Cookies kann zu Funktionseinschr\u00E4nkungen dieses Onlineangebotes f\u00FChren.",
                    React.createElement("br", null),
                    React.createElement("br", null),
                    "Ein genereller Widerspruch gegen den Einsatz der zu Zwecken des Onlinemarketing eingesetzten Cookies kann bei einer Vielzahl der Dienste, vor allem im Fall des Trackings, \u00FCber die US-amerikanische Seite ",
                    React.createElement("a", { href: "http://www.aboutads.info/choices/" }, "http://www.aboutads.info/choices/"),
                    " oder die EU-Seite ",
                    React.createElement("a", { href: "http://www.youronlinechoices.com/" }, "http://www.youronlinechoices.com/"),
                    " erkl\u00E4rt werden. Des Weiteren kann die Speicherung von Cookies mittels deren Abschaltung in den Einstellungen des Browsers erreicht werden. Bitte beachten Sie, dass dann gegebenenfalls nicht alle Funktionen dieses Onlineangebotes genutzt werden k\u00F6nnen."),
                React.createElement("h3", { id: "dsg-general-erasure" }, "L\u00F6schung von Daten"),
                React.createElement("p", null,
                    "Die von uns verarbeiteten Daten werden nach Ma\u00DFgabe der gesetzlichen Vorgaben gel\u00F6scht oder in ihrer Verarbeitung eingeschr\u00E4nkt. Sofern nicht im Rahmen dieser Datenschutzerkl\u00E4rung ausdr\u00FCcklich angegeben, werden die bei uns gespeicherten Daten gel\u00F6scht, sobald sie f\u00FCr ihre Zweckbestimmung nicht mehr erforderlich sind und der L\u00F6schung keine gesetzlichen Aufbewahrungspflichten entgegenstehen. ",
                    React.createElement("br", null),
                    React.createElement("br", null),
                    "Sofern die Daten nicht gel\u00F6scht werden, weil sie f\u00FCr andere und gesetzlich zul\u00E4ssige Zwecke erforderlich sind, wird deren Verarbeitung eingeschr\u00E4nkt. D.h. die Daten werden gesperrt und nicht f\u00FCr andere Zwecke verarbeitet. Das gilt z.B. f\u00FCr Daten, die aus handels- oder steuerrechtlichen Gr\u00FCnden aufbewahrt werden m\u00FCssen."),
                React.createElement("h3", { id: "dsg-general-changes" }, "\u00C4nderungen und Aktualisierungen der Datenschutzerkl\u00E4rung"),
                React.createElement("p", null, "Wir bitten Sie sich regelm\u00E4\u00DFig \u00FCber den Inhalt unserer Datenschutzerkl\u00E4rung zu informieren. Wir passen die Datenschutzerkl\u00E4rung an, sobald die \u00C4nderungen der von uns durchgef\u00FChrten Datenverarbeitungen dies erforderlich machen. Wir informieren Sie, sobald durch die \u00C4nderungen eine Mitwirkungshandlung Ihrerseits (z.B. Einwilligung) oder eine sonstige individuelle Benachrichtigung erforderlich wird."),
                React.createElement("p", null),
                React.createElement("h3", { id: "dsg-services-contractualservices" }, "Vertragliche Leistungen"),
                React.createElement("p", null),
                React.createElement("p", null,
                    React.createElement("span", { className: "ts-muster-content" },
                        "Wir verarbeiten die Daten unserer Vertragspartner und Interessenten sowie anderer Auftraggeber, Kunden, Mandanten, Klienten oder Vertragspartner (einheitlich bezeichnet als \u201EVertragspartner\u201C) entsprechend Art. 6 Abs. 1 lit. b. DSGVO, um ihnen gegen\u00FCber unsere vertraglichen oder vorvertraglichen Leistungen zu erbringen. Die hierbei verarbeiteten Daten, die Art, der Umfang und der Zweck und die Erforderlichkeit ihrer Verarbeitung, bestimmen sich nach dem zugrundeliegenden Vertragsverh\u00E4ltnis. ",
                        React.createElement("br", null),
                        React.createElement("br", null),
                        "Zu den verarbeiteten Daten geh\u00F6ren die Stammdaten unserer Vertragspartner (z.B., Namen und Adressen), Kontaktdaten (z.B. E-Mailadressen und Telefonnummern) sowie Vertragsdaten (z.B., in Anspruch genommene Leistungen, Vertragsinhalte, vertragliche Kommunikation, Namen von Kontaktpersonen) und Zahlungsdaten (z.B.,  Bankverbindungen, Zahlungshistorie). ",
                        React.createElement("br", null),
                        React.createElement("br", null),
                        "Besondere Kategorien personenbezogener Daten verarbeiten wir grunds\u00E4tzlich nicht, au\u00DFer wenn diese Bestandteile einer beauftragten oder vertragsgem\u00E4\u00DFen Verarbeitung sind. ",
                        React.createElement("br", null),
                        React.createElement("br", null),
                        "Wir verarbeiten Daten, die zur Begr\u00FCndung und Erf\u00FCllung der vertraglichen Leistungen erforderlich sind und weisen auf die Erforderlichkeit ihrer Angabe, sofern diese f\u00FCr die Vertragspartner nicht evident ist, hin. Eine Offenlegung an externe Personen oder Unternehmen erfolgt nur, wenn sie im Rahmen eines Vertrags erforderlich ist. Bei der Verarbeitung der uns im Rahmen eines Auftrags \u00FCberlassenen Daten, handeln wir entsprechend den Weisungen der Auftraggeber sowie der gesetzlichen Vorgaben. ",
                        React.createElement("br", null),
                        React.createElement("br", null),
                        "Im Rahmen der Inanspruchnahme unserer Onlinedienste, k\u00F6nnen wir die IP-Adresse und den Zeitpunkt der jeweiligen Nutzerhandlung speichern. Die Speicherung erfolgt auf Grundlage unserer berechtigten Interessen, als auch der Interessen der Nutzer am Schutz vor Missbrauch und sonstiger unbefugter Nutzung. Eine Weitergabe dieser Daten an Dritte erfolgt grunds\u00E4tzlich nicht, au\u00DFer sie ist zur Verfolgung unserer Anspr\u00FCche gem. Art. 6 Abs. 1 lit. f. DSGVO erforderlich oder es besteht hierzu eine gesetzliche Verpflichtung gem. Art. 6 Abs. 1 lit. c. DSGVO.",
                        React.createElement("br", null),
                        React.createElement("br", null),
                        "Die L\u00F6schung der Daten erfolgt, wenn die Daten zur Erf\u00FCllung vertraglicher oder gesetzlicher F\u00FCrsorgepflichten sowie f\u00FCr den Umgang mit etwaigen Gew\u00E4hrleistungs- und vergleichbaren Pflichten nicht mehr erforderlich sind, wobei die Erforderlichkeit der Aufbewahrung der Daten alle drei Jahre \u00FCberpr\u00FCft wird; im \u00DCbrigen gelten die gesetzlichen Aufbewahrungspflichten.",
                        React.createElement("br", null))),
                React.createElement("p", null),
                React.createElement("h3", { id: "dsg-administration" }, "Administration, Finanzbuchhaltung, B\u00FCroorganisation, Kontaktverwaltung"),
                React.createElement("p", null),
                React.createElement("p", null,
                    React.createElement("span", { className: "ts-muster-content" },
                        "Wir verarbeiten Daten im Rahmen von Verwaltungsaufgaben sowie Organisation unseres Betriebs, Finanzbuchhaltung und Befolgung der gesetzlichen Pflichten, wie z.B. der Archivierung. Hierbei verarbeiten wir dieselben Daten, die wir im Rahmen der Erbringung unserer vertraglichen Leistungen verarbeiten. Die Verarbeitungsgrundlagen sind Art. 6 Abs. 1 lit. c. DSGVO, Art. 6 Abs. 1 lit. f. DSGVO. Von der Verarbeitung sind Kunden, Interessenten, Gesch\u00E4ftspartner und Websitebesucher betroffen. Der Zweck und unser Interesse an der Verarbeitung liegt in der Administration, Finanzbuchhaltung, B\u00FCroorganisation, Archivierung von Daten, also Aufgaben die der Aufrechterhaltung unserer Gesch\u00E4ftst\u00E4tigkeiten, Wahrnehmung unserer Aufgaben und Erbringung unserer Leistungen dienen. Die L\u00F6schung der Daten im Hinblick auf vertragliche Leistungen und die vertragliche Kommunikation entspricht den, bei diesen Verarbeitungst\u00E4tigkeiten genannten Angaben.",
                        React.createElement("br", null),
                        React.createElement("br", null),
                        "Wir offenbaren oder \u00FCbermitteln hierbei Daten an die Finanzverwaltung, Berater, wie z.B., Steuerberater oder Wirtschaftspr\u00FCfer sowie weitere Geb\u00FChrenstellen und Zahlungsdienstleister.",
                        React.createElement("br", null),
                        React.createElement("br", null),
                        "Ferner speichern wir auf Grundlage unserer betriebswirtschaftlichen Interessen Angaben zu Lieferanten, Veranstaltern und sonstigen Gesch\u00E4ftspartnern, z.B. zwecks sp\u00E4terer Kontaktaufnahme. Diese mehrheitlich unternehmensbezogenen Daten, speichern wir grunds\u00E4tzlich dauerhaft.",
                        React.createElement("br", null))),
                React.createElement("p", null),
                React.createElement("h3", { id: "dsg-comments" }, "Kommentare und Beitr\u00E4ge"),
                React.createElement("p", null),
                React.createElement("p", null,
                    React.createElement("span", { className: "ts-muster-content" },
                        "Wenn Nutzer Kommentare oder sonstige Beitr\u00E4ge hinterlassen, k\u00F6nnen ihre IP-Adressen auf Grundlage unserer berechtigten Interessen im Sinne des Art. 6 Abs. 1 lit. f. DSGVO f\u00FCr 7 Tage gespeichert werden. Das erfolgt zu unserer Sicherheit, falls jemand in Kommentaren und Beitr\u00E4gen widerrechtliche Inhalte hinterl\u00E4sst (Beleidigungen, verbotene politische Propaganda, etc.). In diesem Fall k\u00F6nnen wir selbst f\u00FCr den Kommentar oder Beitrag belangt werden und sind daher an der Identit\u00E4t des Verfassers interessiert.",
                        React.createElement("br", null),
                        React.createElement("br", null),
                        "Des Weiteren behalten wir uns vor, auf Grundlage unserer berechtigten Interessen gem. Art. 6 Abs. 1 lit. f. DSGVO, die Angaben der Nutzer zwecks Spamerkennung zu verarbeiten.",
                        React.createElement("br", null),
                        React.createElement("br", null),
                        "Auf derselben Rechtsgrundlage behalten wir uns vor, im Fall von Umfragen die IP-Adressen der Nutzer f\u00FCr deren Dauer zu speichern und Cookies zu verwenden, um Mehrfachabstimmungen zu vermeiden.",
                        React.createElement("br", null),
                        React.createElement("br", null),
                        "Die im Rahmen der Kommentare und Beitr\u00E4ge mitgeteilte Informationen zur Person, etwaige Kontakt- sowie Websiteinformationen als auch die inhaltlichen Angaben, werden von uns bis zum Widerspruch der Nutzer dauerhaft gespeichert.")),
                React.createElement("p", null),
                React.createElement("h3", { id: "dsg-subscribetocomments" }, "Kommentarabonnements"),
                React.createElement("p", null),
                React.createElement("p", null,
                    React.createElement("span", { className: "ts-muster-content" },
                        "Die Nachfolgekommentare k\u00F6nnen durch Nutzer mit deren Einwilligung gem. Art. 6 Abs. 1 lit. a DSGVO abonniert werden. Die Nutzer erhalten eine Best\u00E4tigungsemail, um zu \u00FCberpr\u00FCfen, ob sie der Inhaber der eingegebenen Emailadresse sind. Nutzer k\u00F6nnen laufende Kommentarabonnements jederzeit abbestellen. Die Best\u00E4tigungsemail wird Hinweise zu den Widerrufsm\u00F6glichkeiten enthalten. F\u00FCr die Zwecke des Nachweises der Einwilligung der Nutzer, speichern wir den Anmeldezeitpunkt nebst der IP-Adresse der Nutzer und l\u00F6schen diese Informationen, wenn Nutzer sich von dem Abonnement abmelden.",
                        React.createElement("br", null),
                        React.createElement("br", null),
                        "Sie k\u00F6nnen den Empfang unseres Abonnements jederzeit k\u00FCndigen, d.h. Ihre Einwilligungen widerrufen.  Wir k\u00F6nnen die ausgetragenen E-Mailadressen bis zu drei Jahren auf Grundlage unserer berechtigten Interessen speichern bevor wir sie l\u00F6schen, um eine ehemals gegebene Einwilligung nachweisen zu k\u00F6nnen. Die Verarbeitung dieser Daten wird auf den Zweck einer m\u00F6glichen Abwehr von Anspr\u00FCchen beschr\u00E4nkt. Ein individueller L\u00F6schungsantrag ist jederzeit m\u00F6glich, sofern zugleich das ehemalige Bestehen einer Einwilligung best\u00E4tigt wird.")),
                React.createElement("p", null),
                React.createElement("h3", { id: "dsg-contact" }, "Kontaktaufnahme"),
                React.createElement("p", null),
                React.createElement("p", null,
                    React.createElement("span", { className: "ts-muster-content" },
                        "Bei der Kontaktaufnahme mit uns (z.B. per Kontaktformular, E-Mail, Telefon oder via sozialer Medien) werden die Angaben des Nutzers zur Bearbeitung der Kontaktanfrage und deren Abwicklung gem. Art. 6 Abs. 1 lit. b. (im Rahmen vertraglicher-/vorvertraglicher Beziehungen),  Art. 6 Abs. 1 lit. f. (andere Anfragen) DSGVO verarbeitet.. Die Angaben der Nutzer k\u00F6nnen in einem Customer-Relationship-Management System (\"CRM System\") oder vergleichbarer Anfragenorganisation gespeichert werden.",
                        React.createElement("br", null),
                        React.createElement("br", null),
                        "Wir l\u00F6schen die Anfragen, sofern diese nicht mehr erforderlich sind. Wir \u00FCberpr\u00FCfen die Erforderlichkeit alle zwei Jahre; Ferner gelten die gesetzlichen Archivierungspflichten.")),
                React.createElement("p", null),
                React.createElement("h3", { id: "dsg-newsletter-de" }, "Newsletter"),
                React.createElement("p", null),
                React.createElement("p", null,
                    React.createElement("span", { className: "ts-muster-content" },
                        "Mit den nachfolgenden Hinweisen informieren wir Sie \u00FCber die Inhalte unseres Newsletters sowie das Anmelde-, Versand- und das statistische Auswertungsverfahren sowie Ihre Widerspruchsrechte auf. Indem Sie unseren Newsletter abonnieren, erkl\u00E4ren Sie sich mit dem Empfang und den beschriebenen Verfahren einverstanden.",
                        React.createElement("br", null),
                        React.createElement("br", null),
                        "Inhalt des Newsletters: Wir versenden Newsletter, E-Mails und weitere elektronische Benachrichtigungen mit werblichen Informationen (nachfolgend \u201ENewsletter\u201C) nur mit der Einwilligung der Empf\u00E4nger oder einer gesetzlichen Erlaubnis. Sofern im Rahmen einer Anmeldung zum Newsletter dessen Inhalte konkret umschrieben werden, sind sie f\u00FCr die Einwilligung der Nutzer ma\u00DFgeblich. Im \u00DCbrigen enthalten unsere Newsletter Informationen zu unseren Leistungen und uns.",
                        React.createElement("br", null),
                        React.createElement("br", null),
                        "Double-Opt-In und Protokollierung: Die Anmeldung zu unserem Newsletter erfolgt in einem sog. Double-Opt-In-Verfahren. D.h. Sie erhalten nach der Anmeldung eine E-Mail, in der Sie um die Best\u00E4tigung Ihrer Anmeldung gebeten werden. Diese Best\u00E4tigung ist notwendig, damit sich niemand mit fremden E-Mailadressen anmelden kann. Die Anmeldungen zum Newsletter werden protokolliert, um den Anmeldeprozess entsprechend den rechtlichen Anforderungen nachweisen zu k\u00F6nnen. Hierzu geh\u00F6rt die Speicherung des Anmelde- und des Best\u00E4tigungszeitpunkts, als auch der IP-Adresse. Ebenso werden die \u00C4nderungen Ihrer bei dem Versanddienstleister gespeicherten Daten protokolliert.",
                        React.createElement("br", null),
                        React.createElement("br", null),
                        "Anmeldedaten: Um sich f\u00FCr den Newsletter anzumelden, reicht es aus, wenn Sie Ihre E-Mailadresse angeben. Optional bitten wir Sie einen Namen, zwecks pers\u00F6nlicher Ansprache im Newsletters anzugeben.",
                        React.createElement("br", null),
                        React.createElement("br", null),
                        "Der Versand des Newsletters und die mit ihm verbundene Erfolgsmessung erfolgen auf Grundlage einer Einwilligung der Empf\u00E4nger gem. Art. 6 Abs. 1 lit. a, Art. 7 DSGVO i.V.m \u00A7 7 Abs. 2 Nr. 3 UWG oder falls eine Einwilligung nicht erforderlich ist, auf Grundlage unserer berechtigten Interessen am Direktmarketing gem. Art. 6 Abs. 1 lt. f. DSGVO i.V.m. \u00A7 7 Abs. 3 UWG. ",
                        React.createElement("br", null),
                        React.createElement("br", null),
                        "Die Protokollierung des Anmeldeverfahrens erfolgt auf Grundlage unserer berechtigten Interessen gem. Art. 6 Abs. 1 lit. f DSGVO. Unser Interesse richtet sich auf den Einsatz eines nutzerfreundlichen sowie sicheren Newslettersystems, das sowohl unseren gesch\u00E4ftlichen Interessen dient, als auch den Erwartungen der Nutzer entspricht und uns ferner den Nachweis von Einwilligungen erlaubt.",
                        React.createElement("br", null),
                        React.createElement("br", null),
                        "K\u00FCndigung/Widerruf - Sie k\u00F6nnen den Empfang unseres Newsletters jederzeit k\u00FCndigen, d.h. Ihre Einwilligungen widerrufen. Einen Link zur K\u00FCndigung des Newsletters finden Sie am Ende eines jeden Newsletters. Wir k\u00F6nnen die ausgetragenen E-Mailadressen bis zu drei Jahren auf Grundlage unserer berechtigten Interessen speichern bevor wir sie l\u00F6schen, um eine ehemals gegebene Einwilligung nachweisen zu k\u00F6nnen. Die Verarbeitung dieser Daten wird auf den Zweck einer m\u00F6glichen Abwehr von Anspr\u00FCchen beschr\u00E4nkt. Ein individueller L\u00F6schungsantrag ist jederzeit m\u00F6glich, sofern zugleich das ehemalige Bestehen einer Einwilligung best\u00E4tigt wird.")),
                React.createElement("p", null),
                React.createElement("h3", { id: "dsg-hostingprovider" }, "Hosting und E-Mail-Versand"),
                React.createElement("p", null),
                React.createElement("p", null,
                    React.createElement("span", { className: "ts-muster-content" },
                        "Die von uns in Anspruch genommenen Hosting-Leistungen dienen der Zurverf\u00FCgungstellung der folgenden Leistungen: Infrastruktur- und Plattformdienstleistungen, Rechenkapazit\u00E4t, Speicherplatz und Datenbankdienste, E-Mail-Versand, Sicherheitsleistungen sowie technische Wartungsleistungen, die wir zum Zwecke des Betriebs dieses Onlineangebotes einsetzen. ",
                        React.createElement("br", null),
                        React.createElement("br", null),
                        "Hierbei verarbeiten wir, bzw. unser Hostinganbieter Bestandsdaten, Kontaktdaten, Inhaltsdaten, Vertragsdaten, Nutzungsdaten, Meta- und Kommunikationsdaten von Kunden, Interessenten und Besuchern dieses Onlineangebotes auf Grundlage unserer berechtigten Interessen an einer effizienten und sicheren Zurverf\u00FCgungstellung dieses Onlineangebotes gem. Art. 6 Abs. 1 lit. f DSGVO i.V.m. Art. 28 DSGVO (Abschluss Auftragsverarbeitungsvertrag).")),
                React.createElement("p", null),
                React.createElement("h3", { id: "dsg-ga-googleanalytics" }, "Google Analytics"),
                React.createElement("p", null),
                React.createElement("p", null,
                    React.createElement("span", { className: "ts-muster-content" },
                        "Wir setzen auf Grundlage unserer berechtigten Interessen (d.h. Interesse an der Analyse, Optimierung und wirtschaftlichem Betrieb unseres Onlineangebotes im Sinne des Art. 6 Abs. 1 lit. f. DSGVO) Google Analytics, einen Webanalysedienst der Google LLC (\u201EGoogle\u201C) ein. Google verwendet Cookies. Die durch das Cookie erzeugten Informationen \u00FCber Benutzung des Onlineangebotes durch die Nutzer werden in der Regel an einen Server von Google in den USA \u00FCbertragen und dort gespeichert.",
                        React.createElement("br", null),
                        React.createElement("br", null),
                        "Google ist unter dem Privacy-Shield-Abkommen zertifiziert und bietet hierdurch eine Garantie, das europ\u00E4ische Datenschutzrecht einzuhalten (",
                        React.createElement("a", { target: "_blank", href: "https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&status=Active" }, "https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&status=Active"),
                        ").",
                        React.createElement("br", null),
                        React.createElement("br", null),
                        "Google wird diese Informationen in unserem Auftrag benutzen, um die Nutzung unseres Onlineangebotes durch die Nutzer auszuwerten, um Reports \u00FCber die Aktivit\u00E4ten innerhalb dieses Onlineangebotes zusammenzustellen und um weitere, mit der Nutzung dieses Onlineangebotes und der Internetnutzung verbundene Dienstleistungen, uns gegen\u00FCber zu erbringen. Dabei k\u00F6nnen aus den verarbeiteten Daten pseudonyme Nutzungsprofile der Nutzer erstellt werden.",
                        React.createElement("br", null),
                        React.createElement("br", null),
                        "Wir setzen Google Analytics nur mit aktivierter IP-Anonymisierung ein. Das bedeutet, die IP-Adresse der Nutzer wird von Google innerhalb von Mitgliedstaaten der Europ\u00E4ischen Union oder in anderen Vertragsstaaten des Abkommens \u00FCber den Europ\u00E4ischen Wirtschaftsraum gek\u00FCrzt. Nur in Ausnahmef\u00E4llen wird die volle IP-Adresse an einen Server von Google in den USA \u00FCbertragen und dort gek\u00FCrzt.",
                        React.createElement("br", null),
                        React.createElement("br", null),
                        "Die von dem Browser des Nutzers \u00FCbermittelte IP-Adresse wird nicht mit anderen Daten von Google zusammengef\u00FChrt. Die Nutzer k\u00F6nnen die Speicherung der Cookies durch eine entsprechende Einstellung ihrer Browser-Software verhindern; die Nutzer k\u00F6nnen dar\u00FCber hinaus die Erfassung der durch das Cookie erzeugten und auf ihre Nutzung des Onlineangebotes bezogenen Daten an Google sowie die Verarbeitung dieser Daten durch Google verhindern, indem sie das unter folgendem Link verf\u00FCgbare Browser-Plugin herunterladen und installieren:\u00A0",
                        React.createElement("a", { target: "_blank", href: "http://tools.google.com/dlpage/gaoptout?hl=de" }, "http://tools.google.com/dlpage/gaoptout?hl=de"),
                        ".",
                        React.createElement("br", null),
                        React.createElement("br", null),
                        "Weitere Informationen zur Datennutzung durch Google, Einstellungs- und Widerspruchsm\u00F6glichkeiten, erfahren Sie in der Datenschutzerkl\u00E4rung von Google (",
                        React.createElement("a", { target: "_blank", href: "https://policies.google.com/privacy" }, "https://policies.google.com/privacy"),
                        ") sowie in den Einstellungen f\u00FCr die Darstellung von Werbeeinblendungen durch Google ",
                        React.createElement("a", { target: "_blank", href: "https://adssettings.google.com/authenticated" }, "(https://adssettings.google.com/authenticated"),
                        ").",
                        React.createElement("br", null),
                        React.createElement("br", null),
                        "Die personenbezogenen Daten der Nutzer werden nach 14 Monaten gel\u00F6scht oder anonymisiert.")),
                React.createElement("p", null),
                React.createElement("h3", { id: "dsg-tracking-adwords" }, "Google AdWords und Conversion-Messung"),
                React.createElement("p", null),
                React.createElement("p", null,
                    React.createElement("span", { className: "ts-muster-content" },
                        "Wir nutzen auf Grundlage unserer berechtigten Interessen (d.h. Interesse an der Analyse, Optimierung und wirtschaftlichem Betrieb unseres Onlineangebotes im Sinne des Art. 6 Abs. 1 lit. f. DSGVO) die Dienste der Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA, (\u201EGoogle\u201C).",
                        React.createElement("br", null),
                        React.createElement("br", null),
                        "Google ist unter dem Privacy-Shield-Abkommen zertifiziert und bietet hierdurch eine Garantie, das europ\u00E4ische Datenschutzrecht einzuhalten (https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&status=Active).",
                        React.createElement("br", null),
                        React.createElement("br", null),
                        "Wir nutzen das Onlinemarketingverfahren Google \"AdWords\", um Anzeigen im Google-Werbe-Netzwerk zu platzieren (z.B., in Suchergebnissen, in Videos, auf Webseiten, etc.), damit sie Nutzern angezeigt werden, die ein mutma\u00DFliches Interesse an den Anzeigen haben. Dies erlaubt uns Anzeigen f\u00FCr und innerhalb unseres Onlineangebotes gezielter anzuzeigen, um Nutzern nur Anzeigen zu pr\u00E4sentieren, die potentiell deren Interessen entsprechen. Falls einem Nutzer z.B. Anzeigen f\u00FCr Produkte angezeigt werden, f\u00FCr die er sich auf anderen Onlineangeboten interessiert hat, spricht man hierbei vom \u201ERemarketing\u201C. Zu diesen Zwecken wird bei Aufruf unserer und anderer Webseiten, auf denen das Google-Werbe-Netzwerk aktiv ist, unmittelbar durch Google ein Code von Google ausgef\u00FChrt und es werden sog. (Re)marketing-Tags (unsichtbare Grafiken oder Code, auch als \"Web Beacons\" bezeichnet) in die Webseite eingebunden. Mit deren Hilfe wird auf dem Ger\u00E4t der Nutzer ein individuelles Cookie, d.h. eine kleine Datei abgespeichert (statt Cookies k\u00F6nnen auch vergleichbare Technologien verwendet werden). In dieser Datei wird vermerkt, welche Webseiten der Nutzer aufgesucht, f\u00FCr welche Inhalte er sich interessiert und welche Angebote der Nutzer geklickt hat, ferner technische Informationen zum Browser und Betriebssystem, verweisende Webseiten, Besuchszeit sowie weitere Angaben zur Nutzung des Onlineangebotes.",
                        React.createElement("br", null),
                        React.createElement("br", null),
                        "Ferner erhalten  wir ein individuelles \u201EConversion-Cookie\u201C. Die mit Hilfe des Cookies eingeholten Informationen dienen Google dazu, Conversion-Statistiken f\u00FCr uns zu erstellen. Wir erfahren jedoch nur die anonyme Gesamtanzahl der Nutzer, die auf unsere Anzeige geklickt haben und zu einer mit einem Conversion-Tracking-Tag versehenen Seite weitergeleitet wurden. Wir erhalten jedoch keine Informationen, mit denen sich Nutzer pers\u00F6nlich identifizieren lassen.",
                        React.createElement("br", null),
                        React.createElement("br", null),
                        "Die Daten der Nutzer werden im Rahmen des Google-Werbe-Netzwerks pseudonym verarbeitet. D.h. Google speichert und verarbeitet z.B. nicht den Namen oder E-Mailadresse der Nutzer, sondern verarbeitet die relevanten Daten cookie-bezogen innerhalb pseudonymer Nutzerprofile. D.h. aus der Sicht von Google werden die Anzeigen nicht f\u00FCr eine konkret identifizierte Person verwaltet und angezeigt, sondern f\u00FCr den Cookie-Inhaber, unabh\u00E4ngig davon wer dieser Cookie-Inhaber ist. Dies gilt nicht, wenn ein Nutzer Google ausdr\u00FCcklich erlaubt hat, die Daten ohne diese Pseudonymisierung zu verarbeiten. Die \u00FCber die Nutzer gesammelten Informationen werden an Google \u00FCbermittelt und auf Googles Servern in den USA gespeichert.",
                        React.createElement("br", null),
                        React.createElement("br", null),
                        "Weitere Informationen zur Datennutzung durch Google, Einstellungs- und Widerspruchsm\u00F6glichkeiten, erfahren Sie in der Datenschutzerkl\u00E4rung von Google (",
                        React.createElement("a", { target: "_blank", href: "https://policies.google.com/technologies/ads" }, "https://policies.google.com/technologies/ads"),
                        ") sowie in den Einstellungen f\u00FCr die Darstellung von Werbeeinblendungen durch Google ",
                        React.createElement("a", { target: "_blank", href: "https://adssettings.google.com/authenticated" }, "(https://adssettings.google.com/authenticated"),
                        ").")),
                React.createElement("p", null),
                React.createElement("h3", { id: "dsg-socialmedia" }, "Onlinepr\u00E4senzen in sozialen Medien"),
                React.createElement("p", null),
                React.createElement("p", null,
                    React.createElement("span", { className: "ts-muster-content" },
                        "Wir unterhalten Onlinepr\u00E4senzen innerhalb sozialer Netzwerke und Plattformen, um mit den dort aktiven Kunden, Interessenten und Nutzern kommunizieren und sie dort \u00FCber unsere Leistungen informieren zu k\u00F6nnen.",
                        React.createElement("br", null),
                        React.createElement("br", null),
                        "Wir weisen darauf hin, dass dabei Daten der Nutzer au\u00DFerhalb des Raumes der Europ\u00E4ischen Union verarbeitet werden k\u00F6nnen. Hierdurch k\u00F6nnen sich f\u00FCr die Nutzer Risiken ergeben, weil so z.B. die Durchsetzung der Rechte der Nutzer erschwert werden k\u00F6nnte. Im Hinblick auf US-Anbieter die unter dem Privacy-Shield zertifiziert sind, weisen wir darauf hin, dass sie sich damit verpflichten, die Datenschutzstandards der EU einzuhalten.",
                        React.createElement("br", null),
                        React.createElement("br", null),
                        "Ferner werden die Daten der Nutzer im Regelfall f\u00FCr Marktforschungs- und Werbezwecke verarbeitet. So k\u00F6nnen z.B. aus dem Nutzungsverhalten und sich daraus ergebenden Interessen der Nutzer Nutzungsprofile erstellt werden. Die Nutzungsprofile k\u00F6nnen wiederum verwendet werden, um z.B. Werbeanzeigen innerhalb und au\u00DFerhalb der Plattformen zu schalten, die mutma\u00DFlich den Interessen der Nutzer entsprechen. Zu diesen Zwecken werden im Regelfall Cookies auf den Rechnern der Nutzer gespeichert, in denen das Nutzungsverhalten und die Interessen der Nutzer gespeichert werden. Ferner k\u00F6nnen in den Nutzungsprofilen auch Daten unabh\u00E4ngig der von den Nutzern verwendeten Ger\u00E4te gespeichert werden (insbesondere wenn die Nutzer Mitglieder der jeweiligen Plattformen sind und bei diesen eingeloggt sind).",
                        React.createElement("br", null),
                        React.createElement("br", null),
                        "Die Verarbeitung der personenbezogenen Daten der Nutzer erfolgt auf Grundlage unserer berechtigten Interessen an einer effektiven Information der Nutzer und Kommunikation mit den Nutzern gem. Art. 6 Abs. 1 lit. f. DSGVO. Falls die Nutzer von den jeweiligen Anbietern der Plattformen um eine Einwilligung in die vorbeschriebene Datenverarbeitung gebeten werden, ist die Rechtsgrundlage der Verarbeitung Art. 6 Abs. 1 lit. a., Art. 7 DSGVO.",
                        React.createElement("br", null),
                        React.createElement("br", null),
                        "F\u00FCr eine detaillierte Darstellung der jeweiligen Verarbeitungen und der Widerspruchsm\u00F6glichkeiten (Opt-Out), verweisen wir auf die nachfolgend verlinkten Angaben der Anbieter.",
                        React.createElement("br", null),
                        React.createElement("br", null),
                        "Auch im Fall von Auskunftsanfragen und der Geltendmachung von Nutzerrechten, weisen wir darauf hin, dass diese am effektivsten bei den Anbietern geltend gemacht werden k\u00F6nnen. Nur die Anbieter haben jeweils Zugriff auf die Daten der Nutzer und k\u00F6nnen direkt entsprechende Ma\u00DFnahmen ergreifen und Ausk\u00FCnfte geben. Sollten Sie dennoch Hilfe ben\u00F6tigen, dann k\u00F6nnen Sie sich an uns wenden.",
                        React.createElement("br", null),
                        React.createElement("br", null),
                        "- Facebook, -Seiten, -Gruppen, (Facebook Ireland Ltd., 4 Grand Canal Square, Grand Canal Harbour, Dublin 2, Irland) auf Grundlage einer ",
                        React.createElement("a", { target: "_blank", href: "https://www.facebook.com/legal/terms/page_controller_addendum" }, "Vereinbarung \u00FCber gemeinsame Verarbeitung personenbezogener Daten"),
                        " - Datenschutzerkl\u00E4rung: ",
                        React.createElement("a", { target: "_blank", href: "https://www.facebook.com/about/privacy/" }, "https://www.facebook.com/about/privacy/"),
                        ", speziell f\u00FCr Seiten: ",
                        React.createElement("a", { target: "_blank", href: "https://www.facebook.com/legal/terms/information_about_page_insights_data" }, "https://www.facebook.com/legal/terms/information_about_page_insights_data"),
                        " , Opt-Out: ",
                        React.createElement("a", { target: "_blank", href: "https://www.facebook.com/settings?tab=ads" }, "https://www.facebook.com/settings?tab=ads"),
                        " und ",
                        React.createElement("a", { target: "_blank", href: "http://www.youronlinechoices.com" }, "http://www.youronlinechoices.com"),
                        ", Privacy Shield: ",
                        React.createElement("a", { target: "_blank", href: "https://www.privacyshield.gov/participant?id=a2zt0000000GnywAAC&status=Active" }, "https://www.privacyshield.gov/participant?id=a2zt0000000GnywAAC&status=Active"),
                        ".",
                        React.createElement("br", null),
                        React.createElement("br", null),
                        "- Google/ YouTube (Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA) \u2013 Datenschutzerkl\u00E4rung: \u00A0",
                        React.createElement("a", { target: "_blank", href: "https://policies.google.com/privacy" }, "https://policies.google.com/privacy"),
                        ", Opt-Out: ",
                        React.createElement("a", { target: "_blank", href: "https://adssettings.google.com/authenticated" }, "https://adssettings.google.com/authenticated"),
                        ", Privacy Shield: ",
                        React.createElement("a", { target: "_blank", href: "https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&status=Active" }, "https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&status=Active"),
                        ".",
                        React.createElement("br", null),
                        React.createElement("br", null),
                        "- Instagram (Instagram Inc., 1601 Willow Road, Menlo Park, CA, 94025, USA) \u2013 Datenschutzerkl\u00E4rung/ Opt-Out: ",
                        React.createElement("a", { target: "_blank", href: "http://instagram.com/about/legal/privacy/" }, "http://instagram.com/about/legal/privacy/"),
                        ".",
                        React.createElement("br", null),
                        React.createElement("br", null),
                        "- Twitter (Twitter Inc., 1355 Market Street, Suite 900, San Francisco, CA 94103, USA) - Datenschutzerkl\u00E4rung: ",
                        React.createElement("a", { target: "_blank", href: "https://twitter.com/de/privacy" }, "https://twitter.com/de/privacy"),
                        ", Opt-Out: ",
                        React.createElement("a", { target: "_blank", href: "https://twitter.com/personalization" }, "https://twitter.com/personalization"),
                        ", Privacy Shield: ",
                        React.createElement("a", { target: "_blank", href: "https://www.privacyshield.gov/participant?id=a2zt0000000TORzAAO&status=Active" }, "https://www.privacyshield.gov/participant?id=a2zt0000000TORzAAO&status=Active"),
                        ".",
                        React.createElement("br", null),
                        React.createElement("br", null),
                        "- Pinterest (Pinterest Inc., 635 High Street, Palo Alto, CA, 94301, USA) \u2013 Datenschutzerkl\u00E4rung/ Opt-Out: ",
                        React.createElement("a", { target: "_blank", href: "https://about.pinterest.com/de/privacy-policy" }, "https://about.pinterest.com/de/privacy-policy"),
                        ".",
                        React.createElement("br", null),
                        React.createElement("br", null),
                        "- LinkedIn (LinkedIn Ireland Unlimited Company Wilton Place, Dublin 2, Irland) - Datenschutzerkl\u00E4rung ",
                        React.createElement("a", { target: "_blank", href: "https://www.linkedin.com/legal/privacy-policy" }, "https://www.linkedin.com/legal/privacy-policy"),
                        " , Opt-Out: ",
                        React.createElement("a", { target: "_blank", href: "https://www.linkedin.com/psettings/guest-controls/retargeting-opt-out" }, "https://www.linkedin.com/psettings/guest-controls/retargeting-opt-out"),
                        ", Privacy Shield:\u00A0",
                        React.createElement("a", { target: "_blank", href: "https://www.privacyshield.gov/participant?id=a2zt0000000L0UZAA0&status=Active" }, "https://www.privacyshield.gov/participant?id=a2zt0000000L0UZAA0&status=Active"),
                        ".",
                        React.createElement("br", null),
                        React.createElement("br", null),
                        "- Xing (XING AG, Dammtorstra\u00DFe 29-32, 20354 Hamburg, Deutschland) - Datenschutzerkl\u00E4rung/ Opt-Out: ",
                        React.createElement("a", { target: "_blank", href: "https://privacy.xing.com/de/datenschutzerklaerung" }, "https://privacy.xing.com/de/datenschutzerklaerung"),
                        ".",
                        React.createElement("br", null),
                        React.createElement("br", null),
                        "- Wakalet (Wakelet Limited, 76 Quay Street, Manchester, M3 4PR, United Kingdom) - Datenschutzerkl\u00E4rung/ Opt-Out: ",
                        React.createElement("a", { target: "_blank", href: "https://wakelet.com/privacy.html" }, "https://wakelet.com/privacy.html"),
                        ".",
                        React.createElement("br", null),
                        React.createElement("br", null),
                        "- Soundcloud (SoundCloud Limited, Rheinsberger Str. 76/77, 10115 Berlin, Deutschland) - Datenschutzerkl\u00E4rung/ Opt-Out: ",
                        React.createElement("a", { target: "_blank", href: "https://soundcloud.com/pages/privacy" }, "https://soundcloud.com/pages/privacy"),
                        ".")),
                React.createElement("p", null),
                React.createElement("h3", { id: "dsg-thirdparty-einleitung" }, "Einbindung von Diensten und Inhalten Dritter"),
                React.createElement("p", null),
                React.createElement("p", null,
                    React.createElement("span", { className: "ts-muster-content" },
                        "Wir setzen innerhalb unseres Onlineangebotes auf Grundlage unserer berechtigten Interessen (d.h. Interesse an der Analyse, Optimierung und wirtschaftlichem Betrieb unseres Onlineangebotes im Sinne des Art. 6 Abs. 1 lit. f. DSGVO) Inhalts- oder Serviceangebote von Drittanbietern ein, um deren Inhalte und Services, wie z.B. Videos oder Schriftarten einzubinden (nachfolgend einheitlich bezeichnet als \u201CInhalte\u201D). ",
                        React.createElement("br", null),
                        React.createElement("br", null),
                        "Dies setzt immer voraus, dass die Drittanbieter dieser Inhalte, die IP-Adresse der Nutzer wahrnehmen, da sie ohne die IP-Adresse die Inhalte nicht an deren Browser senden k\u00F6nnten. Die IP-Adresse ist damit f\u00FCr die Darstellung dieser Inhalte erforderlich. Wir bem\u00FChen uns nur solche Inhalte zu verwenden, deren jeweilige Anbieter die IP-Adresse lediglich zur Auslieferung der Inhalte verwenden. Drittanbieter k\u00F6nnen ferner so genannte Pixel-Tags (unsichtbare Grafiken, auch als \"Web Beacons\" bezeichnet) f\u00FCr statistische oder Marketingzwecke verwenden. Durch die \"Pixel-Tags\" k\u00F6nnen Informationen, wie der Besucherverkehr auf den Seiten dieser Website ausgewertet werden. Die pseudonymen Informationen k\u00F6nnen ferner in Cookies auf dem Ger\u00E4t der Nutzer gespeichert werden und unter anderem technische Informationen zum Browser und Betriebssystem, verweisende Webseiten, Besuchszeit sowie weitere Angaben zur Nutzung unseres Onlineangebotes enthalten, als auch mit solchen Informationen aus anderen Quellen verbunden werden.")),
                React.createElement("p", null),
                React.createElement("h3", { id: "dsg-thirdparty-youtube" }, "Youtube"),
                React.createElement("p", null),
                React.createElement("p", null,
                    React.createElement("span", { className: "ts-muster-content" },
                        "Wir binden die Videos der Plattform \u201CYouTube\u201D des Anbieters Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA, ein. Datenschutzerkl\u00E4rung: ",
                        React.createElement("a", { target: "_blank", href: "https://www.google.com/policies/privacy/" }, "https://www.google.com/policies/privacy/"),
                        ", Opt-Out: ",
                        React.createElement("a", { target: "_blank", href: "https://adssettings.google.com/authenticated" }, "https://adssettings.google.com/authenticated"),
                        ".")),
                React.createElement("p", null),
                React.createElement("h3", { id: "dsg-thirdparty-googlefonts" }, "Google Fonts"),
                React.createElement("p", null),
                React.createElement("p", null,
                    React.createElement("span", { className: "ts-muster-content" },
                        "Wir binden die Schriftarten (\"Google Fonts\") des Anbieters Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA, ein. Datenschutzerkl\u00E4rung: ",
                        React.createElement("a", { target: "_blank", href: "https://www.google.com/policies/privacy/" }, "https://www.google.com/policies/privacy/"),
                        ", Opt-Out: ",
                        React.createElement("a", { target: "_blank", href: "https://adssettings.google.com/authenticated" }, "https://adssettings.google.com/authenticated"),
                        ".")),
                React.createElement("p", null),
                React.createElement("h3", { id: "dsg-facebook-plugin" }, "Verwendung von Facebook Social Plugins"),
                React.createElement("p", null),
                React.createElement("p", null,
                    React.createElement("span", { className: "ts-muster-content" },
                        "Wir nutzen auf Grundlage unserer berechtigten Interessen (d.h. Interesse an der Analyse, Optimierung und wirtschaftlichem Betrieb unseres Onlineangebotes im Sinne des Art. 6 Abs. 1 lit. f. DSGVO) Social Plugins (\"Plugins\") des sozialen Netzwerkes facebook.com, welches von der Facebook Ireland Ltd., 4 Grand Canal Square, Grand Canal Harbour, Dublin 2, Irland betrieben wird (\"Facebook\"). ",
                        React.createElement("br", null),
                        "Hierzu k\u00F6nnen z.B. Inhalte wie Bilder, Videos oder Texte und Schaltfl\u00E4chen geh\u00F6ren, mit denen Nutzer Inhalte dieses Onlineangebotes innerhalb von Facebook teilen k\u00F6nnen. Die Liste und das Aussehen der Facebook Social Plugins kann hier eingesehen werden:\u00A0",
                        React.createElement("a", { target: "_blank", href: "https://developers.facebook.com/docs/plugins/" }, "https://developers.facebook.com/docs/plugins/"),
                        ".",
                        React.createElement("br", null),
                        React.createElement("br", null),
                        "Facebook ist unter dem Privacy-Shield-Abkommen zertifiziert und bietet hierdurch eine Garantie, das europ\u00E4ische Datenschutzrecht einzuhalten (",
                        React.createElement("a", { target: "_blank", href: "https://www.privacyshield.gov/participant?id=a2zt0000000GnywAAC&status=Active" }, "https://www.privacyshield.gov/participant?id=a2zt0000000GnywAAC&status=Active"),
                        ").",
                        React.createElement("br", null),
                        React.createElement("br", null),
                        "Wenn ein Nutzer eine Funktion dieses Onlineangebotes aufruft, die ein solches Plugin enth\u00E4lt, baut sein Ger\u00E4t eine direkte Verbindung mit den Servern von Facebook auf. Der Inhalt des Plugins wird von Facebook direkt an das Ger\u00E4t des Nutzers \u00FCbermittelt und von diesem in das Onlineangebot eingebunden. Dabei k\u00F6nnen aus den verarbeiteten Daten Nutzungsprofile der Nutzer erstellt werden. Wir haben daher keinen Einfluss auf den Umfang der Daten, die Facebook mit Hilfe dieses Plugins erhebt und informiert die Nutzer daher entsprechend unserem Kenntnisstand.",
                        React.createElement("br", null),
                        React.createElement("br", null),
                        "Durch die Einbindung der Plugins erh\u00E4lt Facebook die Information, dass ein Nutzer die entsprechende Seite des Onlineangebotes aufgerufen hat. Ist der Nutzer bei Facebook eingeloggt, kann Facebook den Besuch seinem Facebook-Konto zuordnen. Wenn Nutzer mit den Plugins interagieren, zum Beispiel den Like Button bet\u00E4tigen oder einen Kommentar abgeben, wird die entsprechende Information von Ihrem Ger\u00E4t direkt an Facebook \u00FCbermittelt und dort gespeichert. Falls ein Nutzer kein Mitglied von Facebook ist, besteht trotzdem die M\u00F6glichkeit, dass Facebook seine IP-Adresse in Erfahrung bringt und speichert. Laut Facebook wird in Deutschland nur eine anonymisierte IP-Adresse gespeichert.",
                        React.createElement("br", null),
                        React.createElement("br", null),
                        "Zweck und Umfang der Datenerhebung und die weitere Verarbeitung und Nutzung der Daten durch Facebook sowie die diesbez\u00FCglichen Rechte und Einstellungsm\u00F6glichkeiten zum Schutz der Privatsph\u00E4re der Nutzer, k\u00F6nnen diese den Datenschutzhinweisen von Facebook entnehmen:\u00A0",
                        React.createElement("a", { target: "_blank", href: "https://www.facebook.com/about/privacy/" }, "https://www.facebook.com/about/privacy/"),
                        ".",
                        React.createElement("br", null),
                        React.createElement("br", null),
                        "Wenn ein Nutzer Facebookmitglied ist und nicht m\u00F6chte, dass Facebook \u00FCber dieses Onlineangebot Daten \u00FCber ihn sammelt und mit seinen bei Facebook gespeicherten Mitgliedsdaten verkn\u00FCpft, muss er sich vor der Nutzung unseres Onlineangebotes bei Facebook ausloggen und seine Cookies l\u00F6schen. Weitere Einstellungen und Widerspr\u00FCche zur Nutzung von Daten f\u00FCr Werbezwecke, sind innerhalb der Facebook-Profileinstellungen m\u00F6glich:\u00A0",
                        React.createElement("a", { target: "_blank", href: "https://www.facebook.com/settings?tab=ads" }, "https://www.facebook.com/settings?tab=ads"),
                        " \u00A0oder \u00FCber die US-amerikanische Seite\u00A0",
                        React.createElement("a", { target: "_blank", href: "http://www.aboutads.info/choices/" }, "http://www.aboutads.info/choices/"),
                        " \u00A0oder die EU-Seite\u00A0",
                        React.createElement("a", { target: "_blank", href: "http://www.youronlinechoices.com/" }, "http://www.youronlinechoices.com/"),
                        ". Die Einstellungen erfolgen plattformunabh\u00E4ngig, d.h. sie werden f\u00FCr alle Ger\u00E4te, wie Desktopcomputer oder mobile Ger\u00E4te \u00FCbernommen.")),
                React.createElement("p", null),
                React.createElement("h3", { id: "dsg-thirdparty-twitter" }, "Twitter"),
                React.createElement("p", null),
                React.createElement("p", null,
                    React.createElement("span", { className: "ts-muster-content" },
                        "Innerhalb unseres Onlineangebotes k\u00F6nnen Funktionen und Inhalte des Dienstes Twitter, angeboten durch die Twitter Inc., 1355 Market Street, Suite 900, San Francisco, CA 94103, USA, eingebunden werden. Hierzu k\u00F6nnen z.B. Inhalte wie Bilder, Videos oder Texte und Schaltfl\u00E4chen geh\u00F6ren, mit denen Nutzer Inhalte dieses Onlineangebotes innerhalb von Twitter teilen k\u00F6nnen.",
                        React.createElement("br", null),
                        "Sofern die Nutzer Mitglieder der Plattform Twitter sind, kann Twitter den Aufruf der o.g. Inhalte und Funktionen den dortigen Profilen der Nutzer zuordnen. Twitter ist unter dem Privacy-Shield-Abkommen zertifiziert und bietet hierdurch eine Garantie, das europ\u00E4ische Datenschutzrecht einzuhalten (",
                        React.createElement("a", { target: "_blank", href: "https://www.privacyshield.gov/participant?id=a2zt0000000TORzAAO&status=Active" }, "https://www.privacyshield.gov/participant?id=a2zt0000000TORzAAO&status=Active"),
                        "). Datenschutzerkl\u00E4rung: ",
                        React.createElement("a", { target: "_blank", href: "https://twitter.com/de/privacy" }, "https://twitter.com/de/privacy"),
                        ", Opt-Out: ",
                        React.createElement("a", { target: "_blank", href: "https://twitter.com/personalization" }, "https://twitter.com/personalization"),
                        ".")),
                React.createElement("a", { href: "https://datenschutz-generator.de", className: "dsg1-6", rel: "nofollow", target: "_blank" }, "Erstellt mit Datenschutz-Generator.de von RA Dr. Thomas Schwenke"))));
    }
    componentDidMount() {
        document.documentElement.scrollTop = 0;
    }
}
exports.DatenschutzView = DatenschutzView;
