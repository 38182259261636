"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
require("./Aecs.style.scss");
const I18n_config_1 = require("../../../Config/I18n.config");
class AecsView extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (React.createElement("div", { className: "view aecs-view" },
            React.createElement("section", null,
                React.createElement("div", { className: "content" },
                    React.createElement("h1", null, "Awesome Entity Component System"),
                    React.createElement("h2", null, "A typescript library"),
                    React.createElement("p", null, I18n_config_1.I18N_CONFIG.en.aecs.p1),
                    React.createElement("p", null,
                        React.createElement("a", { href: "https://gitlab.com/games.bluber/aecs", target: "_blank" }, "Source Code"),
                        "/",
                        React.createElement("a", { href: "https://www.npmjs.com/package/aecs", target: "_blank" }, "NPM Library"))),
                React.createElement("div", { className: "content" },
                    React.createElement("img", { src: "/Assets/Images/aecs.png" }))),
            React.createElement("section", null,
                React.createElement("div", { className: "content" },
                    React.createElement("a", { href: "/Assets/Images/aecs-uml.png", target: "_blank" },
                        React.createElement("img", { src: "/Assets/Images/aecs-uml.png" })),
                    React.createElement("div", { className: "text-section" },
                        React.createElement("h2", null, I18n_config_1.I18N_CONFIG.en.aecs.header3),
                        React.createElement("p", null, I18n_config_1.I18N_CONFIG.en.aecs.p2)))),
            React.createElement("section", null,
                React.createElement("div", { className: "content" },
                    React.createElement("div", { className: "text-section" },
                        React.createElement("h2", null, I18n_config_1.I18N_CONFIG.en.aecs.header4),
                        React.createElement("h3", null, I18n_config_1.I18N_CONFIG.en.aecs.header5),
                        React.createElement("p", null, I18n_config_1.I18N_CONFIG.en.aecs.p3),
                        React.createElement("h3", null, I18n_config_1.I18N_CONFIG.en.aecs.header6),
                        React.createElement("p", null, I18n_config_1.I18N_CONFIG.en.aecs.p4),
                        React.createElement("h3", null, I18n_config_1.I18N_CONFIG.en.aecs.header7),
                        React.createElement("p", null, I18n_config_1.I18N_CONFIG.en.aecs.p5))))));
    }
}
exports.AecsView = AecsView;
