"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
class Sprite {
    constructor(images, frameMillis, width, height) {
        this.images = [];
        this.currentFrame = 0;
        this.counter = 0;
        this.frameMillis = 0;
        this.frameCount = 0;
        this.images = images;
        this.frameMillis = frameMillis;
        this.frameCount = this.images.length;
        this.width = width;
        this.height = height;
        this.timePerFrame = this.frameMillis / this.frameCount;
        console.log(this);
    }
    setCurrentFrame(f) {
        this.currentFrame = f;
    }
    update(animationDuration) {
        this.timePerFrame = animationDuration / this.frameCount;
        if (!this.lastTime)
            this.lastTime = Date.now();
        if ((Date.now() - this.lastTime) > this.timePerFrame) {
            this.currentFrame = (this.currentFrame + 1) % this.frameCount;
            this.lastTime = Date.now();
        }
    }
    draw(ctx, xPos, yPos) {
        ctx.drawImage(this.images[this.currentFrame], xPos, yPos, this.width, this.height);
    }
}
exports.Sprite = Sprite;
