"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
require("./TimeLineModal.style.scss");
class TimeLineModal extends React.Component {
    constructor(props) {
        super(props);
    }
    renderTechnologies() {
        let elems = [];
        for (let tech of this.props.company.details.technologies) {
            elems.push(React.createElement("span", null,
                "- ",
                tech));
        }
        return elems;
    }
    render() {
        if (this.props.company) {
            return (React.createElement("div", { className: "time-line-modal" },
                React.createElement("div", { className: "header" },
                    React.createElement("div", { className: "left" },
                        React.createElement("img", { src: this.props.company.details.imageUrl })),
                    React.createElement("div", { className: "right" },
                        React.createElement("h2", null, this.props.company.name),
                        React.createElement("h3", null, this.props.company.details.jobTitle))),
                React.createElement("div", { className: "description" },
                    React.createElement("p", null, this.props.company.details.jobDescription)),
                React.createElement("h3", { style: { margin: "1rem 0 0.5rem 0" } }, "Involved Technologies:"),
                React.createElement("div", { className: "technologies" }, this.renderTechnologies())));
        }
    }
}
exports.TimeLineModal = TimeLineModal;
