"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
const mobx_1 = require("mobx");
const ScreenDimension_service_1 = require("../../../Services/ScreenDimension.service");
class TopNavigationStore {
    constructor() {
        this.screenX = ScreenDimension_service_1.ScreenDimensionService.screenX;
        ScreenDimension_service_1.ScreenDimensionService.listenForChange((x, y) => {
            this.screenX = x;
        });
    }
}
__decorate([
    mobx_1.observable
], TopNavigationStore.prototype, "screenX", void 0);
exports.TopNavigationStore = TopNavigationStore;
