"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const PineTree_1 = require("./PineTree");
const BetterPine_1 = require("./BetterPine");
class Landscape {
    constructor(width, scale, amplitude, minTree, maxTree, treeLevelLength = 10) {
        this.maxVertices = 256;
        this.maxVerticesMask = this.maxVertices - 1;
        this.amplitude = 15;
        this.scale = 0.02;
        this.r = [];
        this.trees = [];
        this.pines = [];
        for (let i = 0; i < this.maxVertices; i++) {
            this.r.push(Math.random());
        }
        this.minTree = minTree;
        this.maxTree = maxTree;
        this.treeLevelLength = treeLevelLength;
        this.createLandscape(width, scale, amplitude);
    }
    reSeed() {
        for (let i = 0; i < this.maxVertices; i++) {
            this.r.push(Math.random());
        }
    }
    getValue(x) {
        let scaledX = x * this.scale;
        let xFloor = Math.floor(scaledX);
        let t = scaledX - xFloor;
        let tRemapSmoothstep = t * t * (3 - 2 * t);
        let xMin = xFloor & this.maxVerticesMask;
        let xMax = (xMin + 1) & this.maxVerticesMask;
        let y = this.lerp(this.r[xMin], this.r[xMax], tRemapSmoothstep);
        return y * this.amplitude;
    }
    getLandscape(width, treePos) {
        let points = [];
        for (let i = 0; i < width; i++) {
            let pair = {
                x: i,
                y: this.getValue(i)
            };
            points.push(pair);
            if (treePos.indexOf(i) !== -1) {
                this.trees.push(new PineTree_1.PineTree(3, this.treeLevelLength, pair.x, Math.round(pair.y), true));
                this.pines.push(new BetterPine_1.BetterPine(pair.x, Math.round(pair.y), 3, this.treeLevelLength, true));
            }
        }
        return points;
    }
    createLandscape(width, scale, amplitude) {
        this.width = width;
        this.scale = scale;
        this.amplitude = amplitude;
        let treeCount = Math.round(Math.random() * (this.maxTree - this.minTree) + this.minTree);
        let treeX = [];
        for (let i = 0; i < treeCount; i++) {
            treeX.push(Math.round(Math.random() * this.width));
        }
        this.landscape = this.getLandscape(width, treeX);
    }
    draw(ctx, width, height, offsetY, offsetX, color) {
        ctx.save();
        ctx.fillStyle = color;
        ctx.beginPath();
        ctx.moveTo(0, height);
        if (!this.landscape)
            return;
        for (let i = 0; i < this.landscape.length; i++) {
            let pair1 = this.landscape[i];
            ctx.lineTo(pair1.x + offsetX, pair1.y + offsetY);
        }
        ctx.lineTo(width, height);
        ctx.closePath();
        ctx.fill();
        ctx.restore();
        // for(let tree of this.trees) {
        //     tree.draw(ctx, offsetY, color);
        // }
        for (let tree of this.pines) {
            tree.draw(ctx, offsetY, offsetX, color);
        }
    }
    lerp(a, b, t) {
        return a * (1 - t) + b * t;
    }
}
exports.Landscape = Landscape;
