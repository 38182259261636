"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
require("./Syncit.style.scss");
const I18n_config_1 = require("../../../Config/I18n.config");
class SyncitView extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (React.createElement("div", { className: "view syncit-view" },
            React.createElement("section", null,
                React.createElement("div", { className: "content" },
                    React.createElement("h1", null, "SYNCIT"),
                    React.createElement("h2", null, "Reconnect us again"),
                    React.createElement("p", null, I18n_config_1.I18N_CONFIG.en.syncit.p1)),
                React.createElement("div", { className: "content" },
                    React.createElement("img", { src: "/Assets/Images/syncit_header.jpg" }))),
            React.createElement("section", null,
                React.createElement("div", { className: "content" },
                    React.createElement("img", { src: "/Assets/Images/syncit-darmstadt.jpg" }),
                    React.createElement("div", { className: "text-section" },
                        React.createElement("h2", null, I18n_config_1.I18N_CONFIG.en.syncit.header3),
                        React.createElement("p", null, I18n_config_1.I18N_CONFIG.en.syncit.p2)))),
            React.createElement("section", null,
                React.createElement("div", { className: "content" },
                    React.createElement("p", null,
                        React.createElement("img", { src: "/Assets/Images/syncit_prototype01.jpg" }),
                        React.createElement("img", { src: "/Assets/Images/syncit_prototype02.jpg" })),
                    React.createElement("div", { className: "text-section" },
                        React.createElement("h2", null, I18n_config_1.I18N_CONFIG.en.syncit.header4),
                        React.createElement("p", null, I18n_config_1.I18N_CONFIG.en.syncit.p3)))),
            React.createElement("section", null,
                React.createElement("div", { className: "content" },
                    React.createElement("p", null,
                        React.createElement("img", { src: "/Assets/Images/syncit_prototype03.jpg" }),
                        React.createElement("img", { src: "/Assets/Images/syncit_prototype04.jpg" })),
                    React.createElement("div", { className: "text-section" },
                        React.createElement("h2", null, I18n_config_1.I18N_CONFIG.en.syncit.header5),
                        React.createElement("p", null, I18n_config_1.I18N_CONFIG.en.syncit.p4)))),
            React.createElement("section", null,
                React.createElement("div", { className: "content" },
                    React.createElement("p", null,
                        React.createElement("img", { src: "/Assets/Images/pitch_syncit.jpg" })),
                    React.createElement("div", { className: "text-section" },
                        React.createElement("h2", null, I18n_config_1.I18N_CONFIG.en.syncit.header6),
                        React.createElement("p", null, I18n_config_1.I18N_CONFIG.en.syncit.p5)))),
            React.createElement("section", null,
                React.createElement("div", { className: "content" },
                    React.createElement("div", { className: "text-section" },
                        React.createElement("h2", null, I18n_config_1.I18N_CONFIG.en.syncit.header7),
                        React.createElement("p", null, I18n_config_1.I18N_CONFIG.en.syncit.p6))))));
    }
}
exports.SyncitView = SyncitView;
