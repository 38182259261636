"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
require("./ProceduralLandscape.style.scss");
const I18n_config_1 = require("../../../Config/I18n.config");
class ProceduralLandscapeView extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (React.createElement("div", { className: "view procedural-landscape-view" },
            React.createElement("section", null,
                React.createElement("div", { className: "content" },
                    React.createElement("h1", null, I18n_config_1.I18N_CONFIG.en.procedural.h1),
                    React.createElement("h2", null, I18n_config_1.I18N_CONFIG.en.procedural.h2),
                    React.createElement("p", null, I18n_config_1.I18N_CONFIG.en.procedural.p1)),
                React.createElement("div", { className: "content" },
                    React.createElement("img", { src: "/Assets/Images/procedural.jpg" }))),
            React.createElement("section", null,
                React.createElement("div", { className: "content" },
                    React.createElement("img", { src: "/Assets/Images/procedural01.jpg" }),
                    React.createElement("div", { className: "text-section" },
                        React.createElement("h2", null, I18n_config_1.I18N_CONFIG.en.procedural.h3),
                        React.createElement("p", null, I18n_config_1.I18N_CONFIG.en.procedural.p2)))),
            React.createElement("section", null,
                React.createElement("div", { className: "content" },
                    React.createElement("img", { src: "/Assets/Images/procedural02.jpg" }),
                    React.createElement("div", { className: "text-section" },
                        React.createElement("h2", null, I18n_config_1.I18N_CONFIG.en.procedural.h4),
                        React.createElement("p", null, I18n_config_1.I18N_CONFIG.en.procedural.p3)))),
            React.createElement("section", null,
                React.createElement("div", { className: "content" },
                    (() => {
                        if (!this.state) {
                            return React.createElement("canvas", { id: "canvas-example" });
                        }
                        else {
                            return React.createElement("canvas", { id: "canvas-example", width: this.state.width, height: this.state.height });
                        }
                    })(),
                    React.createElement("div", { className: "buttons" },
                        React.createElement("button", { className: "button", onClick: () => { this.props.store.switch(); this.setState({ doParallax: !this.state.doParallax }); } },
                            "Parallax ",
                            ((this.state && this.state.doParallax) ? "On" : "Off")),
                        React.createElement("button", { className: "button", onClick: () => { this.props.store.reseed(); } }, "Reseed")),
                    React.createElement("div", { className: "text-section" },
                        React.createElement("h2", null, I18n_config_1.I18N_CONFIG.en.procedural.h5),
                        React.createElement("p", null, I18n_config_1.I18N_CONFIG.en.procedural.p4))))));
    }
    componentDidMount() {
        if (!this.state) {
            let canvas = document.getElementById("canvas-example");
            this.setState({
                didInit: false,
                width: canvas.offsetWidth,
                height: canvas.offsetHeight,
                doParallax: false
            });
        }
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state && !this.state.didInit) {
            this.setState({ didInit: true });
            this.props.store.initCanvas(document.getElementById("canvas-example"));
        }
    }
    componentWillUnmount() {
        this.props.store.destroyCanvas();
    }
}
exports.ProceduralLandscapeView = ProceduralLandscapeView;
