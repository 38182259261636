"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const axios = require('axios').default;
require("./Contact.style.scss");
const I18n_config_1 = require("../../Config/I18n.config");
const Toast_component_1 = require("../../Components/Desktop/Toast/Toast.component");
class ContactView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            topic: '',
            message: '',
            name: '',
            showToast: false,
            duration: 1000 * 5,
            toastMessage: '',
            toastColor: ''
        };
    }
    onSubmit(e) {
        e.preventDefault();
        console.log(e);
        axios.post("/contact", {
            email: this.state.email,
            topic: this.state.topic,
            text: this.state.message,
            name: this.state.name
        }).then(() => {
            this.setState({
                showToast: true,
                toastMessage: "Message successfully transmitted :)",
                email: "",
                topic: "",
                message: "",
                name: "",
                toastColor: "#AFC10E"
            });
        }).catch(() => {
            this.setState({
                showToast: true,
                toastMessage: "Something went wrong :(",
                toastColor: "#bf4444"
            });
        });
    }
    showToast() {
        if (this.state.showToast) {
            setTimeout(() => {
                this.setState({
                    showToast: false
                });
            }, this.state.duration);
            return React.createElement(Toast_component_1.ToastComponent, { text: this.state.toastMessage, show: this.state.showToast, borderColor: this.state.toastColor });
        }
    }
    render() {
        return (React.createElement("div", { className: "view contact-view" },
            this.showToast(),
            React.createElement("section", null,
                React.createElement("div", { className: "content" },
                    React.createElement("h1", null, I18n_config_1.I18N_CONFIG.en.contact.header),
                    React.createElement("p", null, I18n_config_1.I18N_CONFIG.en.contact.p1),
                    React.createElement("form", { onSubmit: this.onSubmit.bind(this) },
                        React.createElement("div", { className: "row" },
                            React.createElement("input", { type: "text", value: this.state.name, placeholder: "Name", required: true, onChange: (e) => { this.setState({ name: e.target.value }); } }),
                            React.createElement("input", { type: "email", value: this.state.email, placeholder: "Email", required: true, onChange: (e) => { this.setState({ email: e.target.value }); } })),
                        React.createElement("div", { className: "row" },
                            React.createElement("input", { type: "text", value: this.state.topic, placeholder: "Subject", required: true, onChange: (e) => { this.setState({ topic: e.target.value }); } })),
                        React.createElement("div", { className: "row" },
                            React.createElement("textarea", { placeholder: "Message", value: this.state.message, required: true, onChange: (e) => { this.setState({ message: e.target.value }); } })),
                        React.createElement("button", { className: "button", type: "submit" }, "Send"))),
                React.createElement("div", { className: "content world" }))));
    }
}
exports.ContactView = ContactView;
