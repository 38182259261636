"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
require("./Contivis.style.scss");
const I18n_config_1 = require("../../../Config/I18n.config");
class ContivisView extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (React.createElement("div", { className: "view contivis-view" },
            React.createElement("section", null,
                React.createElement("div", { className: "content" },
                    React.createElement("h1", null, I18n_config_1.I18N_CONFIG.en.contivis.h1),
                    React.createElement("h2", null, I18n_config_1.I18N_CONFIG.en.contivis.h2),
                    React.createElement("p", null)),
                React.createElement("div", { className: "content" },
                    React.createElement("img", { src: "/Assets/Images/contivis.jpg" }))),
            React.createElement("section", null,
                React.createElement("div", { className: "content" },
                    React.createElement("p", null,
                        React.createElement("img", { src: "/Assets/Images/contivis-preview.png" })),
                    React.createElement("div", { className: "text-section" },
                        React.createElement("h2", null, I18n_config_1.I18N_CONFIG.en.contivis.h3),
                        React.createElement("p", null, I18n_config_1.I18N_CONFIG.en.contivis.p1))))));
    }
}
exports.ContivisView = ContivisView;
