"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
require("./CanvasSprite.style.scss");
const I18n_config_1 = require("../../../Config/I18n.config");
class CanvasSpriteView extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (React.createElement("div", { className: "view canvas-sprite-view" },
            React.createElement("section", null,
                React.createElement("div", { className: "content" },
                    React.createElement("h1", null, I18n_config_1.I18N_CONFIG.en.anim2D.h1),
                    React.createElement("h2", null, I18n_config_1.I18N_CONFIG.en.anim2D.h2),
                    React.createElement("p", null,
                        I18n_config_1.I18N_CONFIG.en.anim2D.p1 + " ",
                        React.createElement("a", { href: "https://gitlab.com/Blubu/canvas-sprite-animation", target: "_blank" }, I18n_config_1.I18N_CONFIG.en.anim2D.link1))),
                React.createElement("div", { className: "content" },
                    React.createElement("img", { src: "/Assets/Images/2dAnim.jpg" }))),
            React.createElement("section", null,
                React.createElement("div", { className: "content" },
                    (() => {
                        if (!this.state) {
                            return React.createElement("canvas", { id: "canvas-example", onContextMenu: (e) => { e.preventDefault(); return false; } });
                        }
                        else {
                            return React.createElement("canvas", { id: "canvas-example", onContextMenu: (e) => { e.preventDefault(); return false; }, width: this.state.width, height: this.state.height });
                        }
                    })(),
                    React.createElement("p", null, "W, A, S, D to move - Mouse to look around"),
                    React.createElement("div", { className: "text-section" },
                        React.createElement("h2", null, I18n_config_1.I18N_CONFIG.en.anim2D.h3),
                        React.createElement("p", null, I18n_config_1.I18N_CONFIG.en.anim2D.p2))))));
    }
    componentDidMount() {
        if (!this.state) {
            let canvas = document.getElementById("canvas-example");
            this.setState({
                didInit: true,
                width: canvas.offsetWidth,
                height: canvas.offsetHeight
            });
        }
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state && this.state.didInit) {
            this.props.store.initCanvas(document.getElementById("canvas-example"));
        }
    }
    componentWillUnmount() {
        this.props.store.deRegister();
    }
}
exports.CanvasSpriteView = CanvasSpriteView;
