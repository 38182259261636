"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.spriteConfig = {
    player: {
        idle: {
            path: "/Assets/Actors/Player/rifle/idle/survivor-idle_rifle_",
            size: 20,
            width: 313,
            height: 207,
            fileType: 'png'
        },
        meleeattack: {
            path: "/Assets/Actors/Player/rifle/meleeattack/survivor-meleeattack_rifle_",
            size: 15,
            width: 313,
            height: 207,
            fileType: 'png'
        },
        move: {
            path: "/Assets/Actors/Player/rifle/move/survivor-move_rifle_",
            size: 20,
            width: 313,
            height: 207,
            fileType: 'png'
        },
        reload: {
            path: "/Assets/Actors/Player/rifle/reload/survivor-reload_rifle_",
            size: 20,
            width: 313,
            height: 207,
            fileType: 'png'
        },
        shoot: {
            path: "/Assets/Actors/Player/rifle/shoot/survivor-shoot_rifle_",
            size: 3,
            width: 313,
            height: 207,
            fileType: 'png'
        },
        footIdle: {
            path: "/Assets/Actors/Player/feet/idle/survivor-idle_",
            size: 1,
            width: 132,
            height: 155,
            fileType: 'png'
        },
        footRun: {
            path: "/Assets/Actors/Player/feet/run/survivor-run_",
            size: 20,
            width: 204,
            height: 124,
            fileType: 'png'
        }
    }
};
