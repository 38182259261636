"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
class Vector2d {
    constructor(x, y) {
        this.x = x;
        this.y = y;
    }
    isEqual(v) {
        return (v.x === this.x && v.y === this.y);
    }
}
exports.Vector2d = Vector2d;
