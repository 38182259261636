"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const I18n_config_1 = require("../Config/I18n.config");
exports.WORK_LIST = [
    // {
    //     path: "/project/ultimatebackpacks",
    //     header: I18N_CONFIG.en.work.ultimatebackpacks.title,
    //     description: I18N_CONFIG.en.work.ultimatebackpacks.category,
    //     url: ""
    // },
    // {
    //     path: "/project/grimfollowers",
    //     header: I18N_CONFIG.en.work.grimfollowers.title,
    //     description: I18N_CONFIG.en.work.grimfollowers.category,
    //     url: ""
    // },
    {
        path: "/project/earthshader",
        header: I18n_config_1.I18N_CONFIG.en.work.earthshader.title,
        description: I18n_config_1.I18N_CONFIG.en.work.earthshader.category,
        url: "/Assets/Images/01.jpg"
    },
    {
        path: "/project/characteranimation",
        header: I18n_config_1.I18N_CONFIG.en.work.characteranimation.title,
        description: I18n_config_1.I18N_CONFIG.en.work.characteranimation.category,
        url: "/Assets/Images/charactercontroller.png"
    },
    {
        path: "/project/floatingisland",
        header: I18n_config_1.I18N_CONFIG.en.work.floatingislands.title,
        description: I18n_config_1.I18N_CONFIG.en.work.floatingislands.category,
        url: "/Assets/Images/floatingisland.png"
    },
    {
        path: "/project/futurecop",
        header: I18n_config_1.I18N_CONFIG.en.work.futurecop.title,
        description: I18n_config_1.I18N_CONFIG.en.work.futurecop.category,
        url: "/Assets/Images/futurecop.png"
    },
    {
        path: "/project/aecs",
        header: I18n_config_1.I18N_CONFIG.en.work.aecs.title,
        description: I18n_config_1.I18N_CONFIG.en.work.aecs.category,
        url: "/Assets/Images/aecs.png"
    },
    {
        path: "/project/syncit",
        header: I18n_config_1.I18N_CONFIG.en.work.syncit.title,
        description: I18n_config_1.I18N_CONFIG.en.work.syncit.category,
        url: "/Assets/Images/SYNCIT_Bild_4.jpg"
    },
    {
        path: "/project/procedural-landscape",
        header: I18n_config_1.I18N_CONFIG.en.work.procedural.title,
        description: I18n_config_1.I18N_CONFIG.en.work.procedural.category,
        url: "/Assets/Images/procedural.jpg"
    },
    {
        path: "/project/2d-anim-with-canvas",
        header: I18n_config_1.I18N_CONFIG.en.work.anim2D.title,
        description: I18n_config_1.I18N_CONFIG.en.work.anim2D.category,
        url: "/Assets/Images/2dAnim.jpg"
    },
    // {
    //     path: "/project/aecs",
    //     header: "Awesome Entity Component Systen",
    //     description: "TODO",
    //     url: "/Assets/Images/SYNCIT_Bild_4.jpg"
    // },
    // {
    //     path: "/project/open-mace",
    //     header: "Open Mace",
    //     description: "TODO",
    //     url: "/Assets/Images/SYNCIT_Bild_4.jpg"
    // }
    {
        path: "/project/contivis",
        header: I18n_config_1.I18N_CONFIG.en.work.contivis.title,
        description: I18n_config_1.I18N_CONFIG.en.work.contivis.category,
        url: "/Assets/Images/contivis.jpg"
    },
    {
        path: "/project/microgrid",
        header: I18n_config_1.I18N_CONFIG.en.work.microgrid.title,
        description: I18n_config_1.I18N_CONFIG.en.work.microgrid.category,
        url: "/Assets/Images/microgrid.png"
    },
    {
        path: "/project/boulderdash",
        header: I18n_config_1.I18N_CONFIG.en.work.boulderdash.title,
        description: I18n_config_1.I18N_CONFIG.en.work.boulderdash.category,
        url: "/Assets/Images/cover.png"
    }
];
