"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
require("./Landing.style.scss");
const Card_component_1 = require("../../Components/Desktop/Card/Card.component");
const Work_list_1 = require("../../Data/Work.list");
class LandingView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            hoverItem: "-1",
            isIntroFinished: false
        };
    }
    onMouseEnter(e, item) {
        this.setState({
            hoverItem: e
        });
    }
    onMouseOut(e) {
        this.setState({
            hoverItem: "-1"
        });
    }
    getOpacity(item) {
        if (item === this.state.hoverItem || this.state.hoverItem === "-1") {
            return {
                transition: "all 0.5s",
                opacity: 1
            };
        }
        else {
            return {
                transition: "all 0.5s",
                opacity: 0.2
            };
        }
    }
    renderCards() {
        let elems = [];
        for (let i = 0; i < 3; i++) {
            let example = Work_list_1.WORK_LIST[i];
            elems.push(React.createElement("li", { key: i + "_" + example.path, onMouseEnter: this.onMouseEnter.bind(this, "--" + i), onMouseLeave: this.onMouseOut.bind(this) },
                React.createElement(Card_component_1.CardComponent, { header: example.header, description: example.description, imageUrl: example.url, path: example.path, style: this.getOpacity("--" + i) })));
        }
        return elems;
    }
    renderGoodySection() {
        if (this.state.isIntroFinished) {
            return (React.createElement("div", { className: "introduction" },
                React.createElement("h1", null,
                    "Hello there, ",
                    React.createElement("br", null),
                    " my name is Markus."),
                React.createElement("h2", null, "Developer & Tech-Enthusiast")));
        }
        else {
            return (React.createElement("h1", { className: "ml12" }, "A new production"));
        }
    }
    render() {
        return (React.createElement("div", { className: "view landing-view" },
            React.createElement("section", { className: "goody" }, this.renderGoodySection()),
            React.createElement("section", { className: "recent-work" },
                React.createElement("div", { className: "line" },
                    React.createElement("h3", null, "Some of my recent work")),
                React.createElement("ul", null, this.renderCards()))));
    }
    componentDidMount() {
        let sentences = [
            "Smart",
            "Intuitive",
            "Awesome",
            "just kidding..."
        ];
        function replace(text) {
            var textWrapper = document.querySelector('.ml12');
            textWrapper.innerHTML = text;
            textWrapper.innerHTML = textWrapper.textContent.replace(/\S/g, "<span class='letter'>$&</span>");
        }
        const playLoop = (start, end) => {
            if (start < end) {
                replace(sentences[start]);
                anime.timeline({ loop: false })
                    .add({
                    targets: '.ml12 .letter',
                    translateX: [40, 0],
                    translateZ: 0,
                    opacity: [0, 1],
                    easing: "easeOutExpo",
                    duration: 1200,
                    delay: (el, i) => 500 + 30 * i
                }).add({
                    targets: '.ml12 .letter',
                    translateX: [0, -30],
                    opacity: [1, 0],
                    easing: "easeInExpo",
                    duration: 1100,
                    delay: (el, i) => 100 + 30 * i,
                    complete: () => {
                        if (start < end) {
                            playLoop(start + 1, end);
                        }
                    }
                });
            }
            else {
                this.setState({
                    isIntroFinished: true
                });
            }
        };
        playLoop(0, sentences.length);
    }
}
exports.LandingView = LandingView;
