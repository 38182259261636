"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const Player_1 = require("./Actors/Player");
const Sprites_1 = require("./Config/Sprites");
const vector2d_1 = require("./DataModels/vector2d");
const SpriteManager_1 = require("./SpriteManager");
class Main {
    constructor(ctx, width, height) {
        this.keys = {};
        this.ctx = ctx;
        this.width = width;
        this.height = height;
        this.player = new Player_1.Player(new vector2d_1.Vector2d(width / 2, height / 2));
    }
    mouseShit(e) {
        const container = this.gameContainer;
        const mouse = {
            x: 0, y: 0,
            lastX: 0, lastY: 0,
            b1: false, b2: false, b3: false,
            buttonNames: ["b1", "b2", "b3"],
        };
        function mouseEvent(event) {
            var bounds = container.getBoundingClientRect();
            // get the mouse coordinates, subtract the canvas top left and any scrolling
            mouse.x = event.pageX - bounds.left - scrollX;
            mouse.y = event.pageY - bounds.top - scrollY;
            mouse.x /= bounds.width;
            mouse.y /= bounds.height;
            // then scale to canvas coordinates by multiplying the normalized coords with the canvas resolution
            mouse.x *= container.width;
            mouse.y *= container.height;
            if (event.type === "mousedown") {
                mouse[mouse.buttonNames[event.which - 1]] = true; // set the button as down
            }
            else if (event.type === "mouseup") {
                mouse[mouse.buttonNames[event.which - 1]] = false; // set the button up
            }
        }
        mouseEvent(e);
        this.player.setMousePosition(new vector2d_1.Vector2d(mouse.x, mouse.y));
    }
    setGameContainer(container) {
        this.gameContainer = container;
        this.gameContainer.addEventListener('keydown', this.doPlayerMoveStuffTest.bind(this), true);
        this.gameContainer.addEventListener('keyup', this.test2.bind(this), true);
        this.gameContainer.addEventListener('mousemove', this.mouseShit.bind(this), true);
        document.addEventListener('keydown', this.doPlayerMoveStuffTest.bind(this), true);
        document.addEventListener('keyup', this.test2.bind(this), true);
    }
    doPlayerMoveStuffTest(e) {
        this.keys[e.keyCode] = true;
        this.keys['shift'] = e.shiftKey;
        if (this.player.activeState !== 'move') {
            this.player.activeState = 'move';
            SpriteManager_1.spriteManager.anims.player.idle.currentFrame = 0;
        }
    }
    test2(e) {
        this.keys[e.keyCode] = false;
        this.keys['shift'] = e.shiftKey;
        this.player.activeState = 'idle';
        SpriteManager_1.spriteManager.anims.player.move.currentFrame = 0;
    }
    runGame() {
        this.init();
    }
    init() {
        SpriteManager_1.spriteManager.loadConfig(Sprites_1.spriteConfig);
        SpriteManager_1.spriteManager.loadSprites().catch(err => {
            console.error(err, "while loading sprites");
        }).then(() => {
            //all sprites are loaded now start the game!
            this.render();
        });
    }
    update() {
        this.player.update();
        if (this.keys['shift']) {
            this.player.setVelocity(8);
            this.player.setAnimSpeed(2);
        }
        else {
            this.player.setVelocity(6);
            this.player.setAnimSpeed(3);
        }
        if (this.keys[68]) {
            this.player.moveRight();
        }
        if (this.keys[83]) {
            this.player.moveDown();
        }
        if (this.keys[65]) {
            this.player.moveLeft();
        }
        if (this.keys[87]) {
            this.player.moveUp();
        }
    }
    render() {
        this.update();
        this.ctx.clearRect(0, 0, this.width, this.height);
        this.ctx.fillStyle = "#4a4a4a";
        this.ctx.fillRect(0, 0, this.width, this.height);
        this.player.draw(this.ctx);
        this.calcFps();
        requestAnimationFrame(this.render.bind(this));
    }
    calcFps() {
        if (!this.lastCalled) {
            this.lastCalled = performance.now();
            this.fps = 0;
            return;
        }
        let delta = (performance.now() - this.lastCalled) / 1000;
        this.lastCalled = performance.now();
        this.fps = Math.floor(1 / delta);
    }
    destroy() {
        // this.gameContainer.removeEventListener("keydown",);
        this.gameContainer.removeEventListener('keydown', this.doPlayerMoveStuffTest.bind(this), true);
        this.gameContainer.removeEventListener('keyup', this.test2.bind(this), true);
        this.gameContainer.removeEventListener('mousemove', this.mouseShit.bind(this), true);
        document.removeEventListener('keydown', this.doPlayerMoveStuffTest.bind(this), true);
        document.removeEventListener('keyup', this.test2.bind(this), true);
    }
}
exports.Main = Main;
