"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const Renderer_1 = require("./CanvasStuff/Renderer");
class ProceduralLandscapeStore {
    constructor() {
        this.renderer = new Renderer_1.Renderer();
    }
    initCanvas(canvas) {
        this.renderer.offsetTop = 320;
        this.renderer.initEvents();
        this.renderer.init(canvas);
    }
    switch() {
        this.renderer.parallaxSwitch();
    }
    reseed() {
        this.renderer.initScenery();
    }
    destroyCanvas() {
        this.renderer.destroyEvents();
    }
}
exports.ProceduralLandscapeStore = ProceduralLandscapeStore;
