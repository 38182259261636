"use strict";
// const seededRandom = (() => {
//     var seed = 1;
//     return {
//         max : 2576436549074795,
//         reseed (s) {
//             seed = s
//         },
//         random ()  {
//             return seed = ((8765432352450986 * seed) + 8507698654323524) % this.max
//         }
//     }
// })();
// const randSeed = (seed) => seededRandom.reseed(seed|0);
// const randSI = (min = 2, max = min + (min = 0)) => (seededRandom.random() % (max - min)) + min;
// const randS  = (min = 1, max = min + (min = 0)) => (seededRandom.random() / seededRandom.max) * (max - min) + min;
Object.defineProperty(exports, "__esModule", { value: true });
class Seeder {
    constructor(seed) {
        this.max = 2576436549074795;
        this.seed = 1;
        this.seed = seed;
    }
    //give a seed number here!
    reseed(s) {
        this.seed = s;
    }
    random() {
        return this.seed = ((8765432352450986 * this.seed) + 8507698654323524) % this.max;
    }
    randomInteger(min = 2, max = min + (min = 0)) {
        return this.random() % (max - min) + min;
    }
    randomFloat(min = 1, max = min + (min = 0)) {
        return (this.random() / this.max) * (max - min) + min;
    }
}
exports.Seeder = Seeder;
