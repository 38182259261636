"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
require("./About.style.scss");
const I18n_config_1 = require("../../Config/I18n.config");
const TimeLine_component_1 = require("../../Components/Desktop/TimeLine/TimeLine.component");
class AboutView extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (React.createElement("div", { className: "view about-view" },
            React.createElement("section", null,
                React.createElement("div", { className: "content" },
                    React.createElement("h1", null, I18n_config_1.I18N_CONFIG.en.about.header),
                    React.createElement("p", null, I18n_config_1.I18N_CONFIG.en.about.p1),
                    React.createElement("p", null, I18n_config_1.I18N_CONFIG.en.about.p2),
                    React.createElement("p", null, I18n_config_1.I18N_CONFIG.en.about.p3)),
                React.createElement("div", { className: "content" },
                    React.createElement("img", { src: "/Assets/Images/profile_picture.jpg" }))),
            React.createElement("section", { style: { paddingTop: "0" } },
                React.createElement("h2", null, "Timeline"),
                React.createElement(TimeLine_component_1.TimeLineComponent, null))));
    }
}
exports.AboutView = AboutView;
