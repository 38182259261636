"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const BaseActor_1 = require("./Abstract/BaseActor");
const vector2d_1 = require("../DataModels/vector2d");
const SpriteManager_1 = require("../SpriteManager");
class Player extends BaseActor_1.BaseActor {
    constructor(pos) {
        super();
        this.state_idle = 'idle';
        this.state_running = 'move';
        this.activeState = "idle";
        this.lastPos = new vector2d_1.Vector2d(0, 0);
        this.mousePosition = new vector2d_1.Vector2d(0, 0);
        this.velocity = 6;
        this.animSpeed = 3;
        this.anim = SpriteManager_1.spriteManager;
        this.position = pos;
        this.lastPos = pos;
        this.width = 313;
        this.height = 207;
        window['player'] = this;
        window['anim'] = 2000;
    }
    setVelocity(velocity) {
        this.velocity = velocity;
    }
    setAnimSpeed(speed) {
        this.animSpeed = speed;
    }
    update() {
        if (this.activeState === 'move') {
            SpriteManager_1.spriteManager.anims.player.footRun.update(this.animSpeed);
        }
        else {
            SpriteManager_1.spriteManager.anims.player.footIdle.update(2000);
        }
        SpriteManager_1.spriteManager.anims.player[this.activeState].update(window['anim']);
    }
    draw(ctx) {
        let targetX = this.mousePosition.x - this.position.x;
        let targetY = this.mousePosition.y - this.position.y;
        let angle = Math.atan2(targetY, targetX);
        ctx.save();
        ctx.translate(this.position.x, this.position.y);
        ctx.rotate(angle);
        if (this.activeState === 'move') {
            SpriteManager_1.spriteManager.anims.player.footRun.draw(ctx, this.width / -2 + 30, this.height / -2 + 60);
        }
        else {
            SpriteManager_1.spriteManager.anims.player.footIdle.draw(ctx, this.width / -2 + 30, this.height / -2 + 60);
        }
        SpriteManager_1.spriteManager.anims.player[this.activeState].draw(ctx, this.width / -2, this.height / -2);
        ctx.restore();
        ctx.fillStyle = '#ff0010';
        ctx.fillRect(this.position.x - 10, this.position.y - 10, 10, 10);
        ctx.stroke();
    }
    moveUp() {
        this.position.y -= this.velocity;
    }
    moveDown() {
        this.position.y += this.velocity;
    }
    moveLeft() {
        this.position.x -= this.velocity;
    }
    moveRight() {
        this.position.x += this.velocity;
    }
    setMousePosition(v) {
        this.mousePosition = v;
    }
    shoot() {
        console.log("shooting!");
    }
}
exports.Player = Player;
