"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
require("./EarthShader.style.scss");
const I18n_config_1 = require("../../../Config/I18n.config");
class EarthShaderView extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (React.createElement("div", { className: "view earthshader-view" },
            React.createElement("section", null,
                React.createElement("div", { className: "content" },
                    React.createElement("h1", null, "Simple Earth Shader"),
                    React.createElement("h2", null, "Written in HLSL"),
                    React.createElement("p", null, I18n_config_1.I18N_CONFIG.en.earthshader.p1)),
                React.createElement("div", { className: "content" },
                    React.createElement("img", { src: "/Assets/Images/01.jpg" }))),
            React.createElement("section", null,
                React.createElement("div", { className: "content" },
                    React.createElement("div", { className: "text-section" },
                        React.createElement("h2", null, I18n_config_1.I18N_CONFIG.en.earthshader.header1),
                        React.createElement("p", null, I18n_config_1.I18N_CONFIG.en.earthshader.p2),
                        React.createElement("p", null,
                            React.createElement("img", { src: "/Assets/Images/02.jpg" })),
                        React.createElement("p", null,
                            React.createElement("img", { src: "/Assets/Images/03.jpg" })))))));
    }
}
exports.EarthShaderView = EarthShaderView;
