"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
require("./Card.style.scss");
const react_router_dom_1 = require("react-router-dom");
class CardComponent extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (React.createElement("div", { className: "card-component", style: this.props.style },
            React.createElement(react_router_dom_1.Link, { to: this.props.path },
                React.createElement("img", { width: 280, height: 150, style: { background: "url(" + this.props.imageUrl + ")", backgroundSize: "cover" }, className: "image" }),
                React.createElement("div", { className: "description" },
                    React.createElement("div", null,
                        React.createElement("h4", null, this.props.header),
                        React.createElement("span", null, this.props.description))))));
    }
}
exports.CardComponent = CardComponent;
