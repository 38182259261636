"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
require("./CharacterAnimation.style.scss");
const I18n_config_1 = require("../../../Config/I18n.config");
class CharacterAnimationView extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (React.createElement("div", { className: "view characteranimation-view" },
            React.createElement("section", null,
                React.createElement("div", { className: "content" },
                    React.createElement("h1", null, "Unity Character Controller"),
                    React.createElement("h2", null, "With help of animations from mixamo.com"),
                    React.createElement("p", null, I18n_config_1.I18N_CONFIG.en.charactercontroller.p1)),
                React.createElement("div", { className: "content" },
                    React.createElement("img", { src: "/Assets/Images/charactercontroller.png" }))),
            React.createElement("section", null,
                React.createElement("div", { className: "content" },
                    React.createElement("div", { className: "text-section" },
                        React.createElement("h2", null, I18n_config_1.I18N_CONFIG.en.charactercontroller.header1),
                        React.createElement("iframe", { width: "560", height: "315", src: "https://www.youtube.com/embed/_kH-cN-b8GI", title: "YouTube video player", frameBorder: "0", allow: "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture", allowFullScreen: true }),
                        React.createElement("p", null, I18n_config_1.I18N_CONFIG.en.charactercontroller.p2))))));
    }
}
exports.CharacterAnimationView = CharacterAnimationView;
