"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
require("./index.scss");
const TopNavigation_component_1 = require("./Components/Desktop/TopNavigation/TopNavigation.component");
const react_router_dom_1 = require("react-router-dom");
const Landing_view_1 = require("./Views/Landing/Landing.view");
const About_view_1 = require("./Views/About/About.view");
const Skills_view_1 = require("./Views/Skills/Skills.view");
const MyWork_view_1 = require("./Views/MyWork/MyWork.view");
const TopNavigation_store_1 = require("./Components/Desktop/TopNavigation/TopNavigation.store");
const Footer_component_1 = require("./Components/Desktop/Footer/Footer.component");
const Contact_view_1 = require("./Views/Contact/Contact.view");
const Syncit_view_1 = require("./Views/Projects/Syncit/Syncit.view");
const Aecs_view_1 = require("./Views/Projects/AECS/Aecs.view");
const OpenMace_view_1 = require("./Views/Projects/OpenMace/OpenMace.view");
const Contivis_view_1 = require("./Views/Projects/Contivis/Contivis.view");
const Microgrid_view_1 = require("./Views/Projects/Microgrid/Microgrid.view");
const Boulderdash_view_1 = require("./Views/Projects/Boulderdash/Boulderdash.view");
const CanvasSprite_view_1 = require("./Views/Projects/2dSprite/CanvasSprite.view");
const ProceduralLandscape_view_1 = require("./Views/Projects/ProceduralLandscape/ProceduralLandscape.view");
const AnimatedLogo_view_1 = require("./Views/Projects/AnimatedLogo/AnimatedLogo.view");
const Impressum_view_1 = require("./Views/Impressum/Impressum.view");
const Datenschutz_view_1 = require("./Views/Datenschutz/Datenschutz.view");
const ProceduralLandscape_store_1 = require("./Views/Projects/ProceduralLandscape/ProceduralLandscape.store");
const CanvasSprite_store_1 = require("./Views/Projects/2dSprite/CanvasSprite.store");
const FutureCop_view_1 = require("./Views/Projects/FutureCop/FutureCop.view");
const CharacterAnimation_view_1 = require("./Views/Projects/CharacterAnimation/CharacterAnimation.view");
const FloatingIsland_view_1 = require("./Views/Projects/FloatingIsland/FloatingIsland.view");
const EarthShader_view_1 = require("./Views/Projects/EarthShader/EarthShader.view");
class Layout extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        if ( /*/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)*/false) {
            return (React.createElement("div", { className: "layout-mobile" }));
        }
        else {
            return (React.createElement(react_router_dom_1.BrowserRouter, null,
                React.createElement("div", { style: { position: "absolute", left: 0, top: 0 }, id: "scrollCheat" }),
                React.createElement("div", { className: "layout" },
                    React.createElement(TopNavigation_component_1.TopNavigationComponent, { store: new TopNavigation_store_1.TopNavigationStore() }),
                    React.createElement("div", { className: "content" },
                        React.createElement(react_router_dom_1.Switch, null,
                            React.createElement(react_router_dom_1.Route, { exact: true, path: "/", component: (params) => {
                                    return React.createElement(Landing_view_1.LandingView, Object.assign({}, params));
                                } }),
                            React.createElement(react_router_dom_1.Route, { path: "/about", component: (params) => {
                                    return React.createElement(About_view_1.AboutView, Object.assign({}, params));
                                } }),
                            React.createElement(react_router_dom_1.Route, { path: "/skills", component: (params) => {
                                    return React.createElement(Skills_view_1.SkillsView, Object.assign({}, params));
                                } }),
                            React.createElement(react_router_dom_1.Route, { path: "/mywork", component: (params) => {
                                    return React.createElement(MyWork_view_1.MyWorkView, Object.assign({}, params));
                                } }),
                            React.createElement(react_router_dom_1.Route, { path: "/contact", component: (params) => {
                                    return React.createElement(Contact_view_1.ContactView, Object.assign({}, params));
                                } }),
                            React.createElement(react_router_dom_1.Route, { path: "/project/earthshader", component: (params) => {
                                    return React.createElement(EarthShader_view_1.EarthShaderView, Object.assign({}, params));
                                } }),
                            React.createElement(react_router_dom_1.Route, { path: "/project/floatingisland", component: (params) => {
                                    return React.createElement(FloatingIsland_view_1.FloatingIslandView, Object.assign({}, params));
                                } }),
                            React.createElement(react_router_dom_1.Route, { path: "/project/characteranimation", component: (params) => {
                                    return React.createElement(CharacterAnimation_view_1.CharacterAnimationView, Object.assign({}, params));
                                } }),
                            React.createElement(react_router_dom_1.Route, { path: "/project/futurecop", component: (params) => {
                                    return React.createElement(FutureCop_view_1.FutureCopView, Object.assign({}, params));
                                } }),
                            React.createElement(react_router_dom_1.Route, { path: "/project/syncit", component: (params) => {
                                    return React.createElement(Syncit_view_1.SyncitView, Object.assign({}, params));
                                } }),
                            React.createElement(react_router_dom_1.Route, { path: "/project/aecs", component: (params) => {
                                    return React.createElement(Aecs_view_1.AecsView, Object.assign({}, params));
                                } }),
                            React.createElement(react_router_dom_1.Route, { path: "/project/open-mace", component: (params) => {
                                    return React.createElement(OpenMace_view_1.OpenMaceView, Object.assign({}, params));
                                } }),
                            React.createElement(react_router_dom_1.Route, { path: "/project/contivis", component: (params) => {
                                    return React.createElement(Contivis_view_1.ContivisView, Object.assign({}, params));
                                } }),
                            React.createElement(react_router_dom_1.Route, { path: "/project/microgrid", component: (params) => {
                                    return React.createElement(Microgrid_view_1.MicrogridView, Object.assign({}, params));
                                } }),
                            React.createElement(react_router_dom_1.Route, { path: "/project/boulderdash", component: (params) => {
                                    return React.createElement(Boulderdash_view_1.BoulderdashView, Object.assign({}, params));
                                } }),
                            React.createElement(react_router_dom_1.Route, { path: "/project/2d-anim-with-canvas", component: (params) => {
                                    return React.createElement(CanvasSprite_view_1.CanvasSpriteView, Object.assign({ store: new CanvasSprite_store_1.CanvasSpriteStore() }, params));
                                } }),
                            React.createElement(react_router_dom_1.Route, { path: "/project/procedural-landscape", component: (params) => {
                                    return React.createElement(ProceduralLandscape_view_1.ProceduralLandscapeView, Object.assign({ store: new ProceduralLandscape_store_1.ProceduralLandscapeStore() }, params));
                                } }),
                            React.createElement(react_router_dom_1.Route, { path: "/project/animated-logo", component: (params) => {
                                    return React.createElement(AnimatedLogo_view_1.AnimatedLogoView, Object.assign({}, params));
                                } }),
                            React.createElement(react_router_dom_1.Route, { path: "/impressum", component: (params) => {
                                    return React.createElement(Impressum_view_1.ImpressumView, null);
                                } }),
                            React.createElement(react_router_dom_1.Route, { path: "/datenschutz", component: (params) => {
                                    return React.createElement(Datenschutz_view_1.DatenschutzView, null);
                                } }))),
                    React.createElement(Footer_component_1.FooterComponent, null))));
        }
    }
}
exports.Layout = Layout;
