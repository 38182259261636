"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const I18n_config_1 = require("../../../Config/I18n.config");
require("./FutureCop.style.scss");
class FutureCopView extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (React.createElement("div", { className: "view futurecop-view" },
            React.createElement("section", null,
                React.createElement("div", { className: "content" },
                    React.createElement("h1", null, "Future Cop"),
                    React.createElement("h2", null, "A psx classic remake made in Unity Engine"),
                    React.createElement("p", null, I18n_config_1.I18N_CONFIG.en.futurecop.p1)),
                React.createElement("div", { className: "content" },
                    React.createElement("img", { src: "/Assets/Images/futurecop.png" }))),
            React.createElement("section", null,
                React.createElement("div", { className: "content" },
                    React.createElement("div", { className: "text-section" },
                        React.createElement("h2", null, I18n_config_1.I18N_CONFIG.en.futurecop.header1),
                        React.createElement("iframe", { width: "560", height: "315", src: "https://www.youtube.com/embed/Fwts-W2T-cE", title: "YouTube video player", frameBorder: "0", allow: "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture", allowFullScreen: true }),
                        React.createElement("p", null, I18n_config_1.I18N_CONFIG.en.futurecop.p2))))));
    }
}
exports.FutureCopView = FutureCopView;
