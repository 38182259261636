"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
require("./Toast.style.scss");
class ToastComponent extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (React.createElement("div", { className: this.props.show ? "toast-component" : "toast-component hide", style: { border: "3px solid " + this.props.borderColor, borderColor: this.props.borderColor } }, this.props.text));
    }
}
exports.ToastComponent = ToastComponent;
