"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const react_svg_1 = require("react-svg");
require("./TopNavigation.style.scss");
const mobx_react_1 = require("mobx-react");
const react_router_dom_1 = require("react-router-dom");
let TopNavigationComponent = class TopNavigationComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            mobileMenuCollapsed: true
        };
    }
    getMenuItems() {
        return (React.createElement(React.Fragment, null,
            React.createElement(react_router_dom_1.Link, { className: "menu-item", to: "/about" }, "About"),
            React.createElement(react_router_dom_1.Link, { className: "menu-item", to: "/skills" }, "Skills"),
            React.createElement(react_router_dom_1.Link, { className: "menu-item", to: "/mywork" }, "My Work"),
            React.createElement(react_router_dom_1.Link, { className: "menu-item", to: "/contact" }, "Contact"),
            React.createElement("div", { className: "icons" },
                React.createElement("a", { href: "https://www.xing.com/profile/Markus_Lindner53/cv", target: "_blank" },
                    React.createElement(react_svg_1.ReactSVG, { className: "global-svg icon", src: "/Assets/Icons/xing.svg", style: { width: "32px", height: "32px" } })),
                React.createElement("a", { href: "https://www.deviantart.com/bimturton", target: "_blank" },
                    React.createElement(react_svg_1.ReactSVG, { className: "global-svg icon", src: "/Assets/Icons/deviant.svg", style: { width: "32px", height: "32px" } })),
                React.createElement("a", { href: "https://stackoverflow.com/users/5913152/markus-lindner", target: "_blank" },
                    React.createElement(react_svg_1.ReactSVG, { className: "global-svg icon", src: "/Assets/Icons/iconmonstr-stackoverflow-1.svg", style: { width: "32px", height: "32px" } })),
                React.createElement("a", { href: "https://www.linkedin.com/in/markus-lindner-4502191a3/", target: "_blank" },
                    React.createElement(react_svg_1.ReactSVG, { className: "global-svg icon", src: "/Assets/Icons/linkedin.svg", style: { width: "32px", height: "32px" } })))));
    }
    renderMenu() {
        if (this.props.store.screenX < 830) {
            return (React.createElement(React.Fragment, null,
                React.createElement("div", { onClick: () => { this.setState({ mobileMenuCollapsed: !this.state.mobileMenuCollapsed }); } },
                    React.createElement(react_svg_1.ReactSVG, { className: "global-svg icon", src: "/Assets/Icons/burger.svg", style: { width: "32px", height: "32px" } })),
                React.createElement("div", { onClick: () => { this.setState({ mobileMenuCollapsed: true }); }, className: "mobile-menu", style: { display: this.state.mobileMenuCollapsed ? "none" : "flex" } }, this.getMenuItems())));
        }
        else {
            return this.getMenuItems();
        }
    }
    render() {
        return (React.createElement("div", { className: "top-navigation-component" },
            React.createElement("div", { className: "content-wrapper" },
                React.createElement("div", { className: "logo" },
                    React.createElement(react_router_dom_1.Link, { to: "/" }, "L")),
                React.createElement("div", { className: "menu-wrapper" }, this.renderMenu()))));
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.store.screenX >= 830) {
            if (!this.state.mobileMenuCollapsed)
                this.setState({ mobileMenuCollapsed: true });
        }
    }
};
TopNavigationComponent = __decorate([
    mobx_react_1.observer
], TopNavigationComponent);
exports.TopNavigationComponent = TopNavigationComponent;
