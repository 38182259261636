"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.I18N_CONFIG = {
    de: {
        about: {
            header: "Über mich",
            p1: "Seit vielen Jahren in Kontakt mit der Informatik und seit einigen Jahren Professionell im Bereich tätig.",
            p2: "Gut organisierte Person mit einem Auge für das Detail und die Nachhaltigkeit von Projekten. Hat Spaß an Indie Development, Musik hören und machen, design und seit neuem an alten Horrorfilmen. Gerade Student richtung Abschluss, Lebhaft in Darmstadt und in einer WG mit wundervollen Menschen.",
            p3: "Interessiert am gesamten Frontend-Spektrum, kreative Arbeiten mit 2D und 3D, UI und UX Design."
        },
        skills: {
            header: "Skills",
            p1: "Der Hauptbereich meiner Expertise liegt in der Entwicklung von Frontend Anwendungen (Browser seitiger Code)",
            p2: "HTML, CSS, JS (Typescript), entwickeln von kleinen und mittelgroßen Web-Anwendungen mit Angular 2+ und React, Benutzerdefinierte Addons, Browser Addons, Features, Animationen, und interaktive Layouts.",
            p3: "Auch im Bereich der Fullstack Entwicklung habe ich Erfahrungen mit NodeJS und Spring Framework (JAVA). Zusätzlich habe ich Erfahrung im Bereich der 2D Entwicklung (zeichnen, animieren, programmieren) und ein wenig in der 3D Entwicklung (Unity Spiele Engine)",
            p4: "Besuche mein",
            p5: "oder",
            p6: "Profil für mehr Informationen oder",
            p7: "direkt.",
            link1: "LinkedIn",
            link2: "Xing",
            link3: "kontaktiere mich"
        },
        contact: {
            header: "Kontakt",
            p1: "Ich bin interessiert an Freelance Angeboten - besonders kreative und ambitionierte Projekte. Falls Sie andere Anfragen oder Fragen haben, können Sie mich jederzeit kontaktieren. Benutzen Sie einfach das Kontaktformular hier unten."
        },
        work: {
            personal: "Persönliche Projekte",
            university: "Universitäts Projekte",
            caseStudies: "Fallstudien"
        },
        syncit: {
            header1: "SYNCIT",
            header2: "Reconnect us again",
            p1: "SYNCIT is a media tool which allows everybody to synchronize media from the web. No matter if you are in the park with friends and you want to listen to music on several phones simultaneously or you are in a far distance relationship and still want to enjoy your film evenings with your partner, with SYNCIT it will be possible.",
            header3: "The Idea",
            p2: "When I moved out of my hometown there was a big gap between me and my friends and the regular movie evenings became more complicated since my friends and I were about 350km apart. Out of this problem I got the Idea for syncit, a simple platform which synchronizes the streams between multiple clients so that everybody is watching/listening the stream simoultanisly.",
            header4: "The Prototype",
            p3: "In the very early phases of the project I started to build a prototype with NodeJS and SocketIO for the server side code and JQuery on the Client side. The Prototype took in a direct link to a Mp4-File and sent that link to all connected clients. When the stream was ready for every client the server broadcasted a signal to play the file. To keep synchronized through out the session the player commands were broadcasted aswell. So for example when someone wanted to go to the toilet they hit pause and the signal was sent to everybody else aswell.",
            header5: "The Feedback",
            p4: "The feedback was generally positive and throughout the session I had an oppurtunity to see the limits of the software and the ways my friends were able to break the program. Out of the positive feedback I thought about making a commercial product. So I started to visit a course about entrepreneurship and read books about clean software architecture and UI and UX flows. During that time I discovered my love to react and used it to built a more serious product.",
            header6: "The Idea Competition",
            p5: "In the entrepreneurship course they told us that there is the possibility to JOIN the hessia idea competition. Which of course I did. I joined forces with a friend of mine and together we started to wrote a business plan and prepared different pitches for different kinds of audiences. In the first round every team had to send their business plans to the makers of the competition. They then decided which of the ideas would make it to the finals, ours luckily did aswell as about 12 others. There were three months time until the big event were everybody had to pitch in front of the jury aswell as a seperate pitch in front of investors in the pitch-club-frankfurt. So we prepared and tried to make the most out of the given timeframe. TBC...",
            header7: "SYNCIT TODAY",
            p6: "Shit is dead man"
        }
    },
    en: {
        menu: {
            about: "About",
            skills: "Skills",
            work: "My Work",
            contact: "Contact"
        },
        about: {
            header: "About Me",
            p1: "For many years in contact with the information technology and working professionally a couple of years in the industry.",
            p2: "I would describe myself a well-organised person with attention to detail and sustainability for projects. Further I am a fan of indie-development, listening and making music, design and recently of old horror movies. Currently student making his degree, living in Darmstadt, Germany and in a shared apartment with wonderful people.",
            p3: "I am interested in the entire frontend spectrum and creative works regarding 2D and 3D, UI and UX design."
        },
        skillsTldr: {
            p1: "Bachelors Degree Developer with strong skills in 3D, Game Development and Web Development"
        },
        skills3d: {
            p1: "What started out as a hobby and interest for the subject has become more serious for me over the years.",
            p2: "Basic physics simulations, a good foundation in writing HLSL and Compute Shaders, procedural generation (terrain, systems, simulations, 2D, 3D) and animation have become tools in my repertoire. "
        },
        skillsGameDev: {
            p1: "Through the need to interact and explore the 3 dimensional environment I acquired the necessary skills to create interactive simulations, also called video games.",
            p2: "Mostly with unity a good foundation was build on technologies like: Entity-Component-Systems, Animation, Locomotion in 3 dimensions, State Machines, Event Systems, Collision, Ray-Casting and Input Systems."
        },
        skillsWeb: {
            header: "Skills",
            p1: "Through my professional career as a web developer I gained a good knowledge about Web in general (front- and back-end, networking).",
            p2: "HTML, CSS, JS (TypeScript), building all types of web apps with Angular 2+ and React, Customized Addons, Browser Addons, Features, Animation and interactive layouts.",
            p3: "I have also full-stack developer experience with NodeJS and Spring Framework (Java). Additionally I have experience in the area of 2D development for web (drawing, animating, programming)",
            p4: "If you have any questions, ideas, critics or offers, feel free to",
            p5: "or",
            p6: "Profile for further information or",
            p7: "directly.",
            link1: "LinkedIn",
            link2: "Xing",
            link3: "contact me"
        },
        contact: {
            header: "Contact",
            p1: "I am interested in freelance opportunities - especially creative and ambitious projects. However, if you have other requests or questions, don't hesitate to contact me. Just use the contact form below."
        },
        work: {
            ultimatebackpacks: {
                title: "Ultimate Backpacks",
                category: "Game Modification"
            },
            grimfollowers: {
                title: "Grim Followers",
                category: "Game Modification"
            },
            earthshader: {
                title: "Earth Shader",
                category: "University Project"
            },
            characteranimation: {
                title: "Mixamo Unity Character Controller",
                category: "Personal Project"
            },
            floatingislands: {
                title: "Procedural Floating Islands",
                category: "Personal Project"
            },
            futurecop: {
                title: "Future Cop Remake",
                category: "Personal Project"
            },
            aecs: {
                title: "Awesome Entity Component System",
                category: "Personal Project"
            },
            syncit: {
                title: "SYNCIT",
                category: "Personal Project"
            },
            // AECS: ""
            // OpenMace: ""
            microgrid: {
                title: "Microgrid",
                category: "University Project"
            },
            contivis: {
                title: "Contivis",
                category: "University Project"
            },
            boulderdash: {
                title: "Boulderdash",
                category: "University Project"
            },
            anim2D: {
                title: "Sprite Animation",
                category: "Case Study"
            },
            procedural: {
                title: "Procedural Generation",
                category: "Case Study"
            }
            // animatedLogo: ""
        },
        earthshader: {
            p1: "Due to my work on my bachelor thesis with the topic “Optimization of earth observation tools for 3d rendering in a browser”, I created a little planet earth shader for a course about shaders.  The shader is able to render the earth at night or day or somewhere in between, plus it also has a very simplistic approach to render an atmosphere.",
            header1: "Features",
            p2: "Depending on the position of the sun a day or night texture is rendered on the sphere (Textures taken from official NASA page). The texture features normal mapping for the bumpiness of the mountains. For the atmosphere a simple combination of fresnel effects are used."
        },
        charactercontroller: {
            p1: "For a survival game I am working on in my free time I created a character controller which is using free animations from mixamo.com.",
            header1: "Preview",
            p2: "In the current state the character controller features basic locomotion and sneaking animations. In future updates the character controller will also feature climbing and combat animations."
        },
        floatingislands: {
            p1: "In this project I tried to achieve the creation of procedural floating islands. The project uses compute shaders to create a 3d noise and the marching cubes algorithm to build the mesh.",
            header1: "Early Stage",
            p2: "In the beginning I tried to build the floating islands by creating a shape with a circular perlin noise. Out of the shape is then a mesh created and extruded downwards to look more island like. The next step then is to subdivide the whole mesh and use the top vertices to sample a perlin noise function. The problem with this approach though was that the island has a hole on the bottom which is bad if you want to have it floating.",
            header2: "Reworked Version",
            p3: "Later I completely dropped the first approach, when I was reading about 3d noise sampling and marching cubes. The new version samples a 3d noise with a center bias to create an island like shape (density noise). The whole dataset is then used by a marching cubes compute shader to build the mesh out of the data. Sadly marching cubes is a little rough so I guess it makes sense to use some other approach like surface nets for example.",
            header3: "Some Previews"
        },
        futurecop: {
            p1: "One of my favourite games in my youth was 'Future Cop L.A.P.D'. In this game you played a police officer controlling a bipedal mech to stop crime in Los Angeles. The game also had a mode in which you played against your friends with the goal to conquer the opponents base. Inspired by the game and eager to learn unity I took the journey to remake the versus mode with help of the Unity Engine.",
            header1: "The Gameplay",
            p2: "The Clip above shows the versus gameplay in an early but playable version, it was created in the 'high definition render pipeline'. All models and animations were made by me with blender.",
        },
        aecs: {
            p1: "Awesome Entity Component System (AECS) is a library built with typescript. With help of aecs it is easy to create an manage code related to game development. (Screenshot from an example implementation of the library)",
            header3: "The Core",
            p2: "Under the hood it works like a 'normal' entity component system. There a definitions components, entities and systems. All of them are managed by associated manager classes. Additionally a concept of prefabs is added to the core of the system which allows the user to spawn self defined blueprints during runtime.",
            header4: "Additional Features",
            header5: "Pooling System",
            p3: "The integrated pooling system helps to efficiently and memory friendly, manage multiple entities during runtime the of the system.",
            header6: "Event System",
            p4: "With the event system it is possible to communicate from 'outside' with the aecs and also send events between different systems.",
            header7: "Store System",
            p5: "The store system allows each system to have its own memory, which can be read by other systems. It is also possible to read/write data into the store from outside the aecs-ecosystem."
        },
        syncit: {
            header1: "SYNCIT",
            header2: "Reconnect us again",
            p1: "SYNCIT is a media tool which allows everybody to synchronize media from the web. No matter if you are in the park with friends and you want to listen to music on several phones simultaneously or you are in a far distance relationship and still want to enjoy your film evenings with your partner, with SYNCIT it will be possible.",
            header3: "The Idea",
            p2: "When I moved out of my hometown there was a big gap between me and my friends and the regular movie evenings became more complicated since my friends and I were about 350km apart. This problem gave me the Idea for syncit, a simple platform which synchronizes the streams between multiple clients so that everybody is watching/listening the stream simoultanisly.",
            header4: "The Prototype",
            p3: "In the very early phases of the project I started to build a prototype with NodeJS and SocketIO for the server side code and JQuery on the Client side. The Prototype took in a direct link to a Mp4-File and sent that link to all connected clients. When the stream was ready for every client the server broadcasted a signal to play the file. To keep synchronized through out the session the player commands were broadcasted aswell. So for example when someone wanted to go to the toilet, they hit pause and the signal is send to every connected client.",
            header5: "The Feedback",
            p4: "The feedback was generally positive and throughout the session I had an oppurtunity to see the limits of the software and the ways my friends were able to break the program. Out of the positive feedback I thought about making a commercial product. So I started to visit a course about entrepreneurship and read books about clean software architecture and UI and UX flows. During that time I discovered my love to react and used it to built a more serious product.",
            header6: "The Idea Competition",
            p5: "In the entrepreneurship course they told us that there is the possibility to join the hessia idea competition.  I joined forces with a friend of mine and together we started to write a business plan and prepared different pitches for different kinds of audiences. In the first round every team had to send their business plans to the makers of the competition. They then decided which of the ideas would make it to the finals, ours luckily did aswell as about 12 others. There were three months time until the big event were everybody had to pitch in front of the jury aswell as a seperate pitch in front of investors in the pitch-club-frankfurt. So we prepared and tried to make the most out of the given timeframe. Long story short, we did not win. But to be honest it was a great experience and lesson.",
            header7: "SYNCIT Today",
            p6: "After the idea competition the daily live knocked on our doors and we separated and abandoned the project to go after different studies, work and so on. I never really forgot the project and open it up from time to time to code a little. For me it is now a hobby project and not the next facebook I try to chase after."
        },
        contivis: {
            h1: "Contivis",
            h2: "Code Metric Analyzer",
            p1: "With Contivis we had the opportunity to create a tool which visualises statistics and logs for code metrics. We have worked one complete semester on this project with a group of 4 people. The basic structure of the software consists of a NodeJS backend and a very small html-component-framework we wrote ourselves because of fun.",
            h3: "The Product"
        },
        microgrid: {
            h1: "Microgrid",
            h2: "Manages energy demand in a small cluster of Households",
            p1: "",
            h3: "The Task",
            p2: "In this project several groups of students had the opportunity to create a simulation of microgrids. A microgrid is a small collection of households, electric power plants and prosumers (consumers which also generate electricity). If a household for example needs more energy, an automated system will ask inside the grid for energy and a prosumer household or the power plant will send it. To ensure safety in this process we used blockchain technology. In the best case a microgrid is a closed system and everybody in it has enough electricity.",
            h4: "The Product",
            p3: "So the IT-faculty partnered with the environment engineers to create a simulation which takes seasons, daytime, weather, location and other variables into account to simulate how much electricity is needed for each household and how many of them can be in one grid. I was in the UI/UX-Team and we created the control board for the simulation, where you have been able to control several variables of the simulation. To round it all up we created a 2D rendered cluster where you have been able to see where the electricity is going and how much any point in the cluster has produced and consumed."
        },
        boulderdash: {
            h1: "Boulderdash",
            h2: "A LWJGL Game",
            p1: "In Boulderdash the player has the goal to collect all diamonds and reach the goal before the time is up. While moving around and digging tunnels the player must avoid boulders that could fall onto him.",
            h3: "Recreation of the Game",
            p2: "The First university project I have ever had. The goal was to recreate the retro game boulderdash in java. In boulderdash the player needs to dig it's way through a 2D plain which is filled with boulders. When you dig under a boulder the boulder will smash you and you die. To achieve victory you need to collect all diamonds that are hidden in every level. Because it seemed convenient I created a level-editor to build my own levels which was honored with some bonus points for the exam."
        },
        anim2D: {
            h1: "Sprite Animation",
            h2: "HTML5 Canvas Sprite Animator",
            p1: "This project was inspired by a game called \"Darkwood\". I was intrigued how the game could immerse you, even though it is just 2D. The goal was to have a top down character look into the direction of the mouse and be movable with the keyboard.",
            link1: "Have a look at the repository.",
            h3: "The Animation",
            p2: "For the Animation I built a simple sprite object which holds data about the current frame, the time how a long a frame should be displayed and the image data itself. For the initialization a Sprite Manager Class which loads all sprites and holds them in a data structure. Everything comes together in the Player object which can have different states, the states are manipulated via the controls. For every state the player can have a different animation is played."
        },
        procedural: {
            h1: "Procedural Landscape",
            h2: "Made in Canvas 2D",
            p1: "This was a very small hobby project of mine for my former portfolio. To have a little eye candy on the page I wanted to create a 2D-Scenery Parallax effect. The landscape is created with a simple one dimensional noise function to add a little more variety.",
            h3: "How it works",
            p2: "Under the hood is a very simple Noise-Function which takes in an X and returns a corresponding Y value. All Y-values are interpolated, so they are related and not to far apart. All position values are stored in one array and consumed by the Canvas lineTo function which results in a landscape similar 2d graphic.",
            h4: "The Trees",
            p3: "For the Trees I wrote a function which draws one line which is split into 4 parts. All parts are shifted around just a little bit with the random function to appear more natural. Then it just draws four triangles on anchor points on the line and scales each triangle a little bit down the further they go.",
            h5: "Sprinkle a little Parallax",
            p4: "To give it all a sense of depth I added a parallax effect on top of it which is achieved by putting five layers of procedural landscape on top of each other and then moving them around based on the website scrolling and mouse position."
        }
    }
};
