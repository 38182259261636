"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const Seeder_1 = require("./Seeder");
class BetterPine {
    constructor(x, y, levels, levelLength, hasRandomness) {
        this.seed = Math.random() * 10000 | 0;
        this.seeder = new Seeder_1.Seeder(this.seed);
        this.growthRate = 1;
        this.counter = 0;
        this.x = x;
        this.y = y;
        this.levels = levels;
        this.levelLength = levelLength;
        this.hasRandomness = hasRandomness;
    }
    draw(ctx, offsetY, offsetX, color) {
        this.counter = 0;
        this.drawBranch(ctx, this.x + offsetX, this.y + offsetY, -Math.PI / 2, color, 1, 0);
    }
    toRadians(degrees) {
        return degrees * Math.PI / 180;
    }
    drawBranch(ctx, x, y, theta, color = '#000', scale, it) {
        ctx.save();
        ctx.beginPath();
        ctx.moveTo(x, y);
        let randomness = this.toRadians(this.seeder.randomFloat(5, -5));
        if (!this.hasRandomness && it > 0)
            randomness = 0;
        let xx = Math.cos(theta + randomness) * this.levelLength * scale;
        let yy = Math.sin(theta + randomness) * this.levelLength * scale;
        ctx.lineTo(x + xx, y + yy);
        ctx.closePath();
        ctx.strokeStyle = color;
        ctx.stroke();
        ctx.restore();
        this.drawTriangle(ctx, x + xx, y + yy, theta, color, scale * 0.9);
        if (it < this.levels) {
            this.drawBranch(ctx, x + xx, y + yy, theta, color, scale * 0.8, it + 1);
        }
        else {
            this.seeder.reseed(this.seed);
        }
    }
    drawTriangle(ctx, x, y, theta, color, scale) {
        let random = 0;
        if (this.hasRandomness) {
            let min = (this.levelLength * scale / 4) * -1;
            let max = this.levelLength * scale / 4;
            random = this.seeder.randomFloat(min, max);
        }
        ctx.save();
        ctx.translate(x, y);
        ctx.beginPath();
        ctx.moveTo(-this.levelLength * scale, random);
        ctx.lineTo(0, -this.levelLength * scale + random);
        ctx.lineTo(this.levelLength * scale, random);
        ctx.closePath();
        ctx.fillStyle = color;
        ctx.rotate(theta / 2);
        ctx.fill();
        ctx.restore();
    }
}
exports.BetterPine = BetterPine;
