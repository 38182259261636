"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
require("./TimeLine.style.scss");
const ReactModal = require("react-modal");
const TimeLine_config_1 = require("./TimeLine.config");
const TimeLine_modal_1 = require("./TimeLine.modal");
const modalStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        maxWidth: "512px",
        bottom: 'auto',
        // marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
};
class TimeLineComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: false,
            activeId: 0
        };
    }
    onClickTimelineEntry(ev, id) {
        this.setState({ modal: true, activeId: id });
    }
    renderColorLines() {
        let elems = [];
        for (let k in TimeLine_config_1.timeLineData) {
            let entry = TimeLine_config_1.timeLineData[k];
            for (let i = 0; i < entry.length; i++) {
                elems.push(React.createElement("td", null,
                    React.createElement("div", { style: { backgroundColor: TimeLine_config_1.companies[entry[i]].color, height: "7px" } })));
            }
        }
        return elems;
    }
    renderTimeLineGraphic() {
        let elems = [];
        for (let k in TimeLine_config_1.timeLineData) {
            elems.push(React.createElement("td", { className: "line-parent" },
                React.createElement("div", { className: "line" })));
            for (let i = 0; i < 11; i++) {
                elems.push(React.createElement("td", null,
                    React.createElement("div", { className: "line" })));
            }
        }
        return elems;
    }
    renderYearNumbers() {
        let elems = [];
        for (let year in TimeLine_config_1.timeLineData) {
            elems.push(React.createElement("td", { colSpan: 12 }, year));
        }
        return elems;
    }
    renderCompanyItem(name, id, colspan) {
        let elem;
        elem = React.createElement("td", { onClick: (x) => { this.onClickTimelineEntry(x, id); }, className: "timeline-entry", style: { borderColor: TimeLine_config_1.companies[id].color }, colSpan: colspan }, name);
        return elem;
    }
    render() {
        return (React.createElement("div", { className: "time-line-component" },
            React.createElement(ReactModal, { isOpen: this.state.modal, onRequestClose: () => { this.setState({ modal: false }); }, style: modalStyles },
                React.createElement(TimeLine_modal_1.TimeLineModal, { company: TimeLine_config_1.companies[this.state.activeId] })),
            React.createElement("table", { cellPadding: 0, cellSpacing: 0 },
                React.createElement("tbody", null,
                    React.createElement("tr", null,
                        React.createElement("td", { colSpan: 4 }, "."),
                        this.renderCompanyItem("PEAK-14 GmbH", 1, 12),
                        React.createElement("td", null, "."),
                        this.renderCompanyItem("Incloud GmbH", 2, 16),
                        this.renderCompanyItem("Syncit", 3, 3),
                        this.renderCompanyItem("Telespazio-Vega GmbH", 4, 12),
                        React.createElement("td", { colSpan: 3 }, "."),
                        this.renderCompanyItem("Solenix Deutschland GmbH", 5, 25)),
                    React.createElement("tr", null, this.renderColorLines()),
                    React.createElement("tr", { style: { marginTop: "0.5em" } }, this.renderTimeLineGraphic()),
                    React.createElement("tr", null, this.renderYearNumbers())))));
    }
}
exports.TimeLineComponent = TimeLineComponent;
