"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const react_router_dom_1 = require("react-router-dom");
require("./Skills.style.scss");
const Skills_list_1 = require("./Skills.list");
const I18n_config_1 = require("../../Config/I18n.config");
// const skills = {
//     "PIXI-JS", "Progressive Web App"],
//     programs: ["FL-Studio", "Blender", "Pyxel Edit", "Audacity", "Photoshop", "Gimp 2", "DragonBones", "Unity"]
// };
class SkillsView extends React.Component {
    constructor(props) {
        super(props);
    }
    renderSkills(topic) {
        let elems = [];
        for (let i = 0; i < Skills_list_1.SKILLS_LIST[topic].length; i++) {
            elems.push(React.createElement("div", { key: topic + "_" + i, className: "skill-entry" },
                React.createElement("a", { target: "_blank", href: Skills_list_1.SKILLS_LIST[topic][i].url },
                    "#",
                    Skills_list_1.SKILLS_LIST[topic][i].name)));
        }
        return elems;
    }
    render() {
        return (React.createElement("div", { className: "view skills-view" },
            React.createElement("section", null,
                React.createElement("div", { className: "content" },
                    React.createElement("h1", null, "TLDR"),
                    React.createElement("p", null, I18n_config_1.I18N_CONFIG.en.skillsTldr.p1),
                    React.createElement("h1", null, "3D Simulation"),
                    React.createElement("p", null, I18n_config_1.I18N_CONFIG.en.skills3d.p1),
                    React.createElement("p", null, I18n_config_1.I18N_CONFIG.en.skills3d.p2),
                    React.createElement("h1", null, "Game Development"),
                    React.createElement("p", null, I18n_config_1.I18N_CONFIG.en.skillsGameDev.p1),
                    React.createElement("p", null, I18n_config_1.I18N_CONFIG.en.skillsGameDev.p2),
                    React.createElement("h1", null, "Web"),
                    React.createElement("p", null, I18n_config_1.I18N_CONFIG.en.skillsWeb.p1),
                    React.createElement("p", null, I18n_config_1.I18N_CONFIG.en.skillsWeb.p2),
                    React.createElement("p", null, I18n_config_1.I18N_CONFIG.en.skillsWeb.p3),
                    React.createElement("p", null,
                        I18n_config_1.I18N_CONFIG.en.skillsWeb.p4,
                        " ",
                        React.createElement("a", { href: "https://www.linkedin.com/in/markus-lindner-4502191a3/", target: "_blank" }, I18n_config_1.I18N_CONFIG.en.skillsWeb.link1),
                        " ",
                        I18n_config_1.I18N_CONFIG.en.skillsWeb.p5,
                        " ",
                        React.createElement("a", { href: "https://www.xing.com/profile/Markus_Lindner53/cv", target: "_blank" }, I18n_config_1.I18N_CONFIG.en.skillsWeb.link2),
                        " ",
                        I18n_config_1.I18N_CONFIG.en.skillsWeb.p6,
                        " ",
                        React.createElement(react_router_dom_1.Link, { to: "/contact" }, I18n_config_1.I18N_CONFIG.en.skillsWeb.link3),
                        " ",
                        I18n_config_1.I18N_CONFIG.en.skillsWeb.p7)),
                React.createElement("div", { className: "content" },
                    React.createElement("h2", null, "IT-Related"),
                    React.createElement("div", { className: "skills" }, this.renderSkills("itRelated")),
                    React.createElement("h2", null, "Experiences with other Programs"),
                    React.createElement("div", { className: "skills" }, this.renderSkills("programs"))))));
    }
}
exports.SkillsView = SkillsView;
