"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const main_1 = require("./CanvasStuff/main");
class CanvasSpriteStore {
    initCanvas(elem) {
        let ctx = elem.getContext("2d");
        this.renderable = new main_1.Main(ctx, elem.width, elem.height);
        this.renderable.setGameContainer(elem);
        this.renderable.runGame();
    }
    deRegister() {
        this.renderable.destroy();
    }
}
exports.CanvasSpriteStore = CanvasSpriteStore;
