"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const Landscape_1 = require("./Landscape");
const MouseService_1 = require("./MouseService");
class Renderer {
    constructor() {
        this.minTree = 20;
        this.maxTree = 30;
        this.doParallax = false;
        this.doRender = true;
        this.scrollTopStart = 0;
        this.isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    }
    init(element) {
        this.elem = element;
        this.ctx = element.getContext('2d');
        this.ctx.imageSmoothingEnabled = false;
        this.width = element.width;
        this.height = element.height;
        if (!this.isMobile) {
            this.width += 200;
            this.minTree *= 1.1;
            this.maxTree *= 1.1;
        } //needed for vertical parallax
        this.initScenery();
        this.element = element;
        this.draw();
    }
    initScenery() {
        this.trees2 = new Landscape_1.Landscape(this.width, 0.02, 25, this.minTree, this.maxTree, 20);
        this.trees3 = new Landscape_1.Landscape(this.width, 0.03, 25, this.minTree, this.maxTree, 18);
        this.hill1 = new Landscape_1.Landscape(this.width, 0.04, 25, this.minTree, this.maxTree, 12);
        this.hill2 = new Landscape_1.Landscape(this.width, 0.01, 40, this.minTree, this.maxTree, 10);
        this.trees1 = new Landscape_1.Landscape(this.width, 0.01, 20, this.minTree, this.maxTree, 22);
    }
    update() {
    }
    draw() {
        this.update();
        this.ctx.clearRect(0, 0, this.width, this.height);
        this.ctx.lineJoin = 'miter';
        let para = this.scrollTopStart - document.getElementById("root").scrollTop;
        let mouseXparallax = 0;
        if (!this.doParallax)
            para = 0;
        if (!this.isMobile) {
            mouseXparallax = MouseService_1.mouseService.mouseX - this.width / 2;
            this.hill2.draw(this.ctx, this.width, this.height, this.offsetTop - 200 + para * 0.5, -100 + mouseXparallax * 0.01, '#dfedcd');
            this.hill1.draw(this.ctx, this.width, this.height, this.offsetTop - 150 + para * 0.4, -100 + mouseXparallax * 0.015, '#bcd5c2');
            this.trees3.draw(this.ctx, this.width, this.height, this.offsetTop - 60 + para * 0.3, -100 + mouseXparallax * 0.02, '#9abbb3');
            this.trees2.draw(this.ctx, this.width, this.height, this.offsetTop - 30 + para * 0.2, -100 + mouseXparallax * 0.03, '#8e9088');
            this.trees1.draw(this.ctx, this.width, this.height, this.offsetTop, -100 + mouseXparallax * 0.04, '#3d4e50');
        }
        else {
            this.hill2.draw(this.ctx, this.width, this.height, this.offsetTop - 200 + para * 0.5, mouseXparallax, '#dfedcd');
            this.hill1.draw(this.ctx, this.width, this.height, this.offsetTop - 150 + para * 0.4, mouseXparallax, '#bcd5c2');
            this.trees3.draw(this.ctx, this.width, this.height, this.offsetTop - 60 + para * 0.3, mouseXparallax, '#9abbb3');
            this.trees2.draw(this.ctx, this.width, this.height, this.offsetTop - 30 + para * 0.2, mouseXparallax, '#8e9088');
            this.trees1.draw(this.ctx, this.width, this.height, this.offsetTop, mouseXparallax, '#3d4e50');
        }
        this.ctx.save();
        if (this.doRender)
            this.animationFrame = requestAnimationFrame(() => {
                this.draw();
            });
    }
    initEvents() {
        // document.getElementById("root").addEventListener('scroll', ()=>{this.doParallax = true, console.log("SCROLL")}, false);
    }
    parallaxSwitch() {
        this.scrollTopStart = document.getElementById("root").scrollTop;
        this.doParallax = !this.doParallax;
    }
    destroyEvents() {
        document.getElementById("root").removeEventListener('scroll', () => { this.doParallax = false; this.doRender = false; }, false);
        cancelAnimationFrame(this.animationFrame);
        this.init = () => { };
        this.initEvents = () => { };
        this.initScenery = () => { };
        this.update = () => { };
        this.draw = () => { };
        for (let k in this) {
            delete this[k];
        }
    }
}
exports.Renderer = Renderer;
