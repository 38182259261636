"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const Sprite_1 = require("./Sprite");
class SpriteManager {
    constructor() {
        this.anims = {};
        this.config = {};
    }
    loadConfig(config) {
        this.config = config;
    }
    loadSprites() {
        return __awaiter(this, void 0, void 0, function* () {
            for (let actor in this.config) {
                this.anims[actor] = {};
                for (let animation in this.config[actor]) {
                    let tmp = this.config[actor][animation];
                    let images = yield this.loadImageSet(tmp.path, tmp.size, tmp.fileType);
                    this.anims[actor][animation] = new Sprite_1.Sprite(images, 3, tmp.width, tmp.height);
                }
            }
        });
    }
    loadImageSet(path, size, fileType) {
        return __awaiter(this, void 0, void 0, function* () {
            let images = [];
            for (let i = 0; i < size; i++) {
                images.push(yield this.loadImage(path + i + '.' + fileType));
            }
            return images;
        });
    }
    loadImage(path) {
        return __awaiter(this, void 0, void 0, function* () {
            return new Promise((resolve, reject) => {
                let img = new Image();
                img.addEventListener('load', e => resolve(img));
                img.addEventListener('error', () => {
                    reject(new Error(`Failed to load image's URL: ${path}`));
                });
                img.src = path;
            });
        });
    }
}
exports.SpriteManager = SpriteManager;
exports.spriteManager = new SpriteManager();
