"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
require("./MyWork.style.scss");
const Card_component_1 = require("../../Components/Desktop/Card/Card.component");
const Work_list_1 = require("../../Data/Work.list");
class MyWorkView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            hoverItem: "-1"
        };
    }
    onMouseEnter(e, item) {
        this.setState({
            hoverItem: e
        });
    }
    onMouseOut(e) {
        this.setState({
            hoverItem: "-1"
        });
    }
    getOpacity(item) {
        if (item === this.state.hoverItem || this.state.hoverItem === "-1") {
            return {
                transition: "all 0.5s",
                opacity: 1
            };
        }
        else {
            return {
                transition: "all 0.5s",
                opacity: 0.2
            };
        }
    }
    renderCards() {
        let elems = [];
        let outer = 0;
        // for(let key in workingExamples) {
        for (let i = 0; i < Work_list_1.WORK_LIST.length; i++) {
            let example = Work_list_1.WORK_LIST[i];
            elems.push(React.createElement("li", { key: example.path, onMouseEnter: this.onMouseEnter.bind(this, "" + outer + "-" + i), onMouseLeave: this.onMouseOut.bind(this) },
                React.createElement(Card_component_1.CardComponent, { header: example.header, description: example.description, imageUrl: example.url, path: example.path, style: this.getOpacity("" + outer + "-" + i) })));
        }
        outer++;
        // }
        return elems;
    }
    render() {
        return (React.createElement("div", { className: "my-work-view" },
            React.createElement("section", null,
                React.createElement("ul", null, this.renderCards()))));
    }
}
exports.MyWorkView = MyWorkView;
