"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
require("./Boulderdash.style.scss");
const I18n_config_1 = require("../../../Config/I18n.config");
class BoulderdashView extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (React.createElement("div", { className: "view boulderdash-view" },
            React.createElement("section", null,
                React.createElement("div", { className: "content" },
                    React.createElement("h1", null, I18n_config_1.I18N_CONFIG.en.boulderdash.h1),
                    React.createElement("h2", null, I18n_config_1.I18N_CONFIG.en.boulderdash.h2),
                    React.createElement("p", null, I18n_config_1.I18N_CONFIG.en.boulderdash.p1)),
                React.createElement("div", { className: "content" },
                    React.createElement("img", { src: "/Assets/Images/cover.png" }))),
            React.createElement("section", null,
                React.createElement("div", { className: "content" },
                    React.createElement("p", null,
                        React.createElement("img", { src: "/Assets/Images/boulderdash1.png" }),
                        React.createElement("img", { src: "/Assets/Images/boulderdash2.png" })),
                    React.createElement("div", { className: "text-section" },
                        React.createElement("h2", null, I18n_config_1.I18N_CONFIG.en.boulderdash.h3),
                        React.createElement("p", null, I18n_config_1.I18N_CONFIG.en.boulderdash.p2))))));
    }
}
exports.BoulderdashView = BoulderdashView;
